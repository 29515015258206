export type DownloadJsonProps = {
    json: object
}

export const downloadJson: (fileName: string, value: object) => void = (fileName, value) => {
    const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(value, null, 2));
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", fileName);
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
}