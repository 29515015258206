import React from "react";
import {MapContainer, Marker, Polygon, Polyline, Popup, TileLayer} from "react-leaflet";
import {DELIVERY_POINT_ICON, END_POINT_ICON, STARTING_POINT_ICON} from "../../../../iconurls/IconUrls";
import {LatLngExpression} from "leaflet";
import {Coordinate} from "../../../../types/route/Coordinate";
import {ClusteredDeliveryPoint, DeliveryPoint, isClustered} from "../../../../types/route/DeliveryPoint";
import {Box} from "@cloudscape-design/components";
import {MlmdProcessOutput} from "../../../../types/route/mlmd/MlmdProcessOutput";
import {BasicLeg} from "../../../../types/route/common/BasicLeg";

type MlmdRouteOutputViewProps = {
    input: MlmdProcessOutput,
};

function createArrow(a: Coordinate, b: Coordinate, arrowLength: number = 0.0003, arrowWidthDegrees: number = 20): Coordinate[] {
    // Calculate the vector from A to B
    const vectorAB = { x: b.latitude - a.latitude, y: b.longitude - a.longitude };

    // Calculate the length of AB
    const lengthAB = Math.sqrt(vectorAB.x ** 2 + vectorAB.y ** 2);

    // Normalize the direction vector
    const unitVectorAB = { x: vectorAB.x / lengthAB, y: vectorAB.y / lengthAB };

    // Calculate unit vectors for the arrowhead sides
    const arrowAngleRadians = arrowWidthDegrees * (Math.PI / 180);
    const unitVectorArrowSide1 = {
        x: unitVectorAB.x * Math.cos(arrowAngleRadians) - unitVectorAB.y * Math.sin(arrowAngleRadians),
        y: unitVectorAB.x * Math.sin(arrowAngleRadians) + unitVectorAB.y * Math.cos(arrowAngleRadians)
    };
    const unitVectorArrowSide2 = {
        x: unitVectorAB.x * Math.cos(-arrowAngleRadians) - unitVectorAB.y * Math.sin(-arrowAngleRadians),
        y: unitVectorAB.x * Math.sin(-arrowAngleRadians) + unitVectorAB.y * Math.cos(-arrowAngleRadians)
    };

    // Calculate the arrowhead points
    const arrowPoint1 = { latitude: b.latitude - arrowLength * unitVectorArrowSide1.x, longitude: b.longitude - arrowLength * unitVectorArrowSide1.y };
    const arrowPoint2 = { latitude: b.latitude - arrowLength * unitVectorArrowSide2.x, longitude: b.longitude - arrowLength * unitVectorArrowSide2.y };

    // Return the points that form the arrow (including the original end point B)
    return [arrowPoint1, b, arrowPoint2];
}

const DeliveryPointPopUp : React.FC<{arrivalTime?: string, input : DeliveryPoint}> = ({arrivalTime, input}) => {
    return (
        <Box>
            <p><strong>DeliveryPoint</strong></p>
            {arrivalTime && <p>DeliveryTime: {arrivalTime}</p>}
            <p>ClusterIndex: {input.clusterIndex}</p>
            <p>Priority: {input.priority}</p>
            <p>Category: {input.category}</p>
            <p>Desi: {input.desi}</p>
        </Box>);
}

const ClusteredDeliveryPointPopUp : React.FC<{arrivalTime: string, input : ClusteredDeliveryPoint}> = ({arrivalTime, input}) => {
    return (
        <Box>
            <p><strong>ClusteredDeliveryPoint</strong></p>
            <p>DeliveryTime: {arrivalTime}</p>
            <p>ClusterIndex: {input.clusterIndex}</p>
            <p>Priority: {input.priority}</p>
            <p>OriginalDeliveryPoint count: {input.originalDeliveryPoints.length}</p>
        </Box>);
}

const MapRoute : React.FC<{legs: BasicLeg[]}> = ({legs}) => {
    const start = legs[0].point.coordinate;
    const end = legs[legs.length-1].point.coordinate;
    const getHue = (index: number) => (360 * index / legs.length) % 360;

    return (
        <>
            <Marker key={'startPoint'}  position={{lng: start.longitude, lat:start.latitude}} icon={STARTING_POINT_ICON}>
                <Popup>
                    A pretty CSS3 popup. <br /> Easily customizable.
                </Popup>
            </Marker>
            <Marker key={'endPoint'} position={{lng: end.longitude, lat:end.latitude}} icon={END_POINT_ICON} >
                <Popup>
                    A pretty CSS3 popup. <br /> Easily customizable.
                </Popup>
            </Marker>
            {legs.slice(1,legs.length-1).map(leg => (
                <Marker key={`Marker-${leg.arrivalTime}`} position={{lng: leg.point.coordinate.longitude, lat:leg.point.coordinate.latitude}} icon={DELIVERY_POINT_ICON}>
                    <Popup>
                        {isClustered(leg.point) ? (
                            <ClusteredDeliveryPointPopUp arrivalTime={leg.arrivalTime} input={leg.point}/>
                        ) : (
                            <DeliveryPointPopUp input={leg.point} arrivalTime={leg.arrivalTime}/>
                        )}
                    </Popup>
                </Marker>
            ))}
            {legs.map((leg, index) => {
                // Assuming each leg represents a move from the previous leg's end point to the current leg's start point
                if (index === 0) return null; // Skip the first leg since there's no previous point to connect from

                const prevLeg = legs[index - 1];
                const positions: LatLngExpression[] = [
                    { lat: prevLeg.point.coordinate.latitude, lng: prevLeg.point.coordinate.longitude }, // Previous leg's coordinate
                    { lat: leg.point.coordinate.latitude, lng: leg.point.coordinate.longitude }, // Current leg's coordinate
                ];
                const color = `hsl(${getHue(index)}, 100%, 50%)`; // Generate a color for the polyline
                const triangle: Coordinate[] = createArrow(prevLeg.point.coordinate, leg.point.coordinate);

                return (
                    <>
                        <Polyline
                            key={`Marker-${index}`}
                            positions={positions}
                            pathOptions={{ color }}
                        />
                        <Polygon
                            key={`Polygon-${index}`}
                            positions={triangle.map(c => {return {lat: c.latitude, lng: c.longitude}})}
                            color={color}
                            fill={true}
                            fillColor={color}
                            fillOpacity={1}
                        />
                    </>
                );
            })}
        </>
    );
}

const MlmdRouteOutputView : React.FC<MlmdRouteOutputViewProps> = ({input}) => {
    const length = input.optimalRoute.deliveryRoutes.map(dr => dr.legs).flat().length;
    const center = input.optimalRoute.deliveryRoutes.map(dr => dr.legs).flat().map(basicLeg=> basicLeg.point.coordinate).reduce((acc, cur) => ({
        latitude: acc.latitude + cur.latitude / length,
        longitude: acc.longitude + cur.longitude / length
    }), { latitude: 0, longitude: 0 });

    return (
        <MapContainer key={'MapContainer'} center={{lng: center.longitude, lat:center.latitude}} zoom={13} style={{ height: '400px', width: '100%' }}>
            <TileLayer
                key={'tileLayer'}
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {input.optimalRoute.deliveryRoutes.map(deliveryRoute => <MapRoute legs={deliveryRoute.legs}/>)}
        </MapContainer>
    );
}

export default MlmdRouteOutputView;