import React, {useEffect, useState} from "react";
import {PostProductResponse} from "../../../types/route/PostProductResponse";
import {useUser} from "../../../context/UserContext";
import {useApiClient} from "../../../client/OptimaSoftClient";
import {useTabContext} from "../lastmiledelivery/context/TabContext";
import {Box, Button, FileUpload, Flashbar, Header, Link, Modal, Spinner} from "@cloudscape-design/components";
import {downloadJson} from "../../../util/downloadUtil";
import FileIcon from "../icon/FileIcon";
import SendIcon from "../icon/SendIcon";
import {sampleDistanceMatrixInput} from "./samples/SampleDistanceMatrixInput";
import {DistanceMatrixInput} from "../../../types/route/distancematrix/DistanceMatrixInput";
import DistanceMatrixOutputView from "./components/DistanceMatrixOutputView";

const DistanceMatrixNewRequest: React.FC = () => {
    const [distanceMatrixInput, setDistanceMatrixInput] = useState<DistanceMatrixInput>();
    const [postProductResponse, setPostProductResponse] = useState<PostProductResponse|undefined>();
    const [requestFileVisible, setRequestFileVisible] = React.useState(false);
    const [files, setFiles] = useState<File[]>([]);
    const {apiKey } = useUser();
    const {distanceMatrixClient} = useApiClient();
    const [isRestLoading, setIsRestLoading] = useState(false);
    const {setActiveTabId} = useTabContext();

    useEffect(() => {
        if(files.length === 1) {
            const firstFile = files[0];
            firstFile.text().then(text => setDistanceMatrixInput(JSON.parse(text as string)));
        }
    }, [files]);

    const sendRequest = () => {
        if(!apiKey || !distanceMatrixInput)
            return;
        setIsRestLoading(true);
        distanceMatrixClient.postDistanceMatrixRequest(apiKey, distanceMatrixInput)
            .then(lmdResponse => setPostProductResponse(lmdResponse))
            .finally(() => setIsRestLoading(false));
    };


    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '30px'}}>
            <Box>
                <Header>Send Your Distance Matrix Request</Header>
                <p>To calculate the Distance matrix and potentially use it in your application,
                    you will need to provide the list of coordinates in a JSON format.
                </p>
                <p>Not sure how to format your request? Download our <Link onFollow={(e) =>
                    downloadJson('SampleDistanceMatrixInput.json', sampleDistanceMatrixInput)}>sample JSON file<FileIcon/></Link>to get started.
                    This template includes the format of the input file and example values to guide you through creating your request.</p>
                <p>How to Send Your Request:</p>
                <ol>
                    <li><strong>Prepare Your JSON File:</strong> Ensure your file follows the format shown in the sample JSON.
                        For efficiency, make sure that your input doesn't contain duplicates as it will create unnecessary records.</li>
                    <li><strong>Upload Your File:</strong> Use the upload section below to select and upload your JSON file.</li>
                    <li><strong>Send Your Request:</strong> Once your file is uploaded, click the "Send Request" button.
                        You will see a status indicator while our servers process your request. This may take a few moments.</li>
                    <li><strong>View Your Results:</strong> After processing, the distance matrix will be displayed on this page.</li>
                </ol>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                    <FileUpload
                        onChange={({ detail }) => setFiles(detail.value)}
                        value={files}
                        i18nStrings={{
                            uploadButtonText: e =>
                                e ? "Choose files" : "Choose file",
                            dropzoneText: e =>
                                e
                                    ? "Drop files to upload"
                                    : "Drop file to upload",
                            removeFileAriaLabel: e =>
                                `Remove file ${e + 1}`,
                            limitShowFewer: "Show fewer files",
                            limitShowMore: "Show more files",
                            errorIconAriaLabel: "Error"
                        }}
                        multiple={false}
                        showFileLastModified
                        showFileSize
                        showFileThumbnail
                        tokenLimit={3}
                        constraintText="Distance Matrix Request Json File"
                    />
                    {distanceMatrixInput && <>
                        <Button onClick={() => setRequestFileVisible(true)}>
                            Preview Json
                        </Button>
                        <Modal
                            onDismiss={() => setRequestFileVisible(false)}
                            visible={requestFileVisible}
                            header="Distance Matrix Request Json File Preview">
                                <pre style={{ whiteSpace: 'pre', wordBreak: 'break-word', margin: '0', padding: '0.5rem' , overflowX: 'scroll'}}>
                                    {JSON.stringify(distanceMatrixInput, null, 2)}
                                </pre>
                        </Modal>
                    </>
                    }
                </div>
            </Box>
            <Box>
                <Button onClick={sendRequest} disabled={!distanceMatrixInput || isRestLoading}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px'}}>
                        {isRestLoading ? (
                            <>
                                <Spinner/>
                                <div>Sending Request</div>
                            </>
                        ) : (
                            <>
                                <SendIcon/>
                                <div>Send Request</div>
                            </>
                        )}
                    </div>
                </Button>
            </Box>
            {postProductResponse && (
                <Flashbar items={[{
                    id: 'success-flashbar',
                    type: 'success',
                    dismissible: true,
                    dismissLabel: 'Dismiss Distance Matrix Request Id',
                    onDismiss: () => setPostProductResponse(undefined),
                    content: (
                        <>
                            The request has been <strong>successfully</strong> dispatched to the server.
                            You can now check the current status of the processing or examine the outcome below.
                            You can also view the request in the
                            <Link color="inverted" variant='primary' onFollow={e=> setActiveTabId('history')}>History tab</Link>.
                            For Reference, your requestId is {postProductResponse?.requestId}
                        </>
                    )
                }]}/>
            )}
            {postProductResponse?.requestId && <DistanceMatrixOutputView distanceMatrixId={postProductResponse.requestId}/>}
        </div>
    );
};

export default DistanceMatrixNewRequest;