import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import AccountOptionsPage from "../options/AccountOptionsPage";
import {HomePage} from "../home/HomePage";
import DistanceMatrix from "../product/route/distancematrix/DistanceMatrix";
import MultiLastMileDelivery from "../product/route/multilastmiledelivery/MultiLastMileDelivery";
import LastMileDelivery from "../product/route/lastmiledelivery/LastMileDelivery";
import ApplicationLayout from "./ApplicationLayout";
import Admin from "../product/route/admin/Admin";

const MainRouter: React.FC = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<ApplicationLayout/>} >
                    <Route index element={<HomePage/>} />
                    <Route path="/options" element={<AccountOptionsPage/>} />
                    <Route path="/admin" element={<Admin/>} />
                    <Route path="/distance-matrix" element={<DistanceMatrix />} />
                    <Route path="/last-mile-delivery" element={<LastMileDelivery />} />
                    <Route path="/multi-last-mile-delivery" element={<MultiLastMileDelivery />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default MainRouter;