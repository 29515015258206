import {LmdClient} from "./products/LmdClient";
import {DistanceMatrixClient} from "./products/DistanceMatrixClient";
import {MlmdClient} from "./products/MlmdClient";
import {ConfigurationClient} from "./products/ConfigurationClient";

export const useApiClient = () => {

    const lmdClient = new LmdClient();
    const mlmdClient = new MlmdClient();
    const distanceMatrixClient = new DistanceMatrixClient();
    const configurationClient = new ConfigurationClient();

    return { lmdClient, mlmdClient, distanceMatrixClient, configurationClient};
};
