import React, {createContext, useContext, useState, useEffect, PropsWithChildren} from 'react';
import {jwtDecode} from "jwt-decode";
import {fetchAuthSession} from "@aws-amplify/auth";

export enum UserGroup {
    ADMIN = "Admin",
}

// Define the structure of the user data, including the API key
interface UserContextType {
    apiKey: string;
    email: string;
    company: string;
    name: string;
    email_verified: string;
    groups: Set<UserGroup>;
}

// Creating the context with an undefined initial value
const UserContext = createContext<UserContextType | undefined>(undefined);

// UserProvider component
export const UserProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [userData, setUserData] = useState<UserContextType | undefined>();
    const [loading, setLoading] = useState(true); // Add a loading state

    useEffect(() => {
        // Function to fetch user data, including the API key
        const fetchUserData = async () => {
            try {
                const session = await fetchAuthSession();
                const jwtToken = parseJwtToken(session.tokens!.idToken!.toString());
                setUserData({
                    apiKey: jwtToken!["custom:apikey"],
                    company: jwtToken!["custom:company"],
                    email_verified: jwtToken!.email_verified,
                    email: jwtToken!.email,
                    name: jwtToken!.name,
                    groups: new Set(jwtToken?.["cognito:groups"]?.filter((value): value is UserGroup => Object.values(UserGroup).includes(value as UserGroup)).map(value => value as UserGroup) || [])
                }); // Assuming the response has an 'apiKey' field
                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch user data:', error);
                setLoading(false);
                return <div>Failed to load JwtToken. Please refresh the page</div>;
            }
        };

        fetchUserData();
    }, []);

    if (loading) {
        return <div>Loading...</div>; // Or any other loading indicator
    }

    return (
        <UserContext.Provider value={{ ...userData! }}>
            {children}
        </UserContext.Provider>
    );
};

// Custom hook to use the UserContext
export const useUser = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
};

interface JwtToken {
    'cognito:username': string;
    email: string;
    'custom:company': string;
    name: string;
    email_verified: string;
    'custom:apikey': string;
    'cognito:groups': string[];
}

function parseJwtToken(token: string): JwtToken | undefined {
    try {
        return jwtDecode<JwtToken>(token!);
    } catch (error) {
        console.error("Failed to decode token or extract custom field:", error);
    }
}