import React, {createContext, useContext, useState, PropsWithChildren, useEffect} from 'react';
import {useLocation, useNavigate} from "react-router-dom";

// Define the structure of the user data, including the API key
interface TabContextType {
    activeTabId: string;
    setActiveTabId: (value: string) => void;
}

// Creating the context with an undefined initial value
const TabContext = createContext<TabContextType | undefined>(undefined);

// UserProvider component
interface TabContextProviderProps {
    defaultTab?: string;
}

export const TabContextProvider: React.FC<PropsWithChildren<TabContextProviderProps>> = ({ children, defaultTab = 'introduction' }) => {
    const navigate = useNavigate();
    const location = useLocation();

    // Use defaultTab if the 'tab' parameter is missing
    const initialTabId = new URLSearchParams(location.search).get('tab') || defaultTab;
    const [activeTabId, setActiveTabId] = useState<string>(initialTabId);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        params.set('tab', activeTabId);
        navigate({ search: params.toString() }, { replace: true });
    }, [activeTabId, navigate, location.search]);

    return (
        <TabContext.Provider value={{ activeTabId, setActiveTabId }}>
            {children}
        </TabContext.Provider>
    );
};

// Custom hook to use the UserContext
export const useTabContext = () => {
    const context = useContext(TabContext);
    if (!context) {
        throw new Error('useTabContext must be used within a TabContextProvider');
    }
    return context;
};
