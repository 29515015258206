import React, {useEffect, useState} from "react";
import {LmdProcessOutput} from "../../../../types/route/lmd/LmdProcessOutput";
import {GetLmdOutput} from "../../../../types/route/lmd/GetLmdOutput";
import {ColumnLayout, Container, Header, SpaceBetween} from "@cloudscape-design/components";
import ValueWithLabel from "../../../../components/ValueWithLabel";
import LmdRouteOutputView from "./LmdRouteOutputView";
import {useUser} from "../../../../context/UserContext";
import {useApiClient} from "../../../../client/OptimaSoftClient";
import {useFileServiceClient} from "../../../../client/FileServiceClient";
import {ProcessStatus} from "../../../../types/route/ProcessStatus";
import {AbstractRouteOutputView} from "../../compontents/AbstractRouteOutputView";

type LmdOutputViewProps = {
    lmdId: string
};

const LmdOutputView : React.FC<LmdOutputViewProps> = ({lmdId}) => {
    const [isOptimizationProcessing, setIsOptimizationProcessing] = useState(false);
    const {apiKey} = useUser();
    const {lmdClient} = useApiClient();
    const [lmdOutput, setLmdOutput] = useState<GetLmdOutput|undefined>();
    const [lmdRouteOutput, setLmdRouteOutput] = useState<LmdProcessOutput|undefined>();
    const fileServiceClient = useFileServiceClient();

    useEffect(() => {
        let intervalId: NodeJS.Timer;

        const fetchLmdResult = async () => {
            if(!lmdId || !apiKey) {
                return;
            }
            setIsOptimizationProcessing(true);

            // Simulate the API call
            const response: GetLmdOutput = await lmdClient.getLmdResult(apiKey, lmdId);

            if(response.status === ProcessStatus.FINISHED || response.status === ProcessStatus.FAILED) {
                clearInterval(intervalId);
                setIsOptimizationProcessing(false);
            }

            setLmdOutput(response);
        };

        // Call the function immediately on component mount
        fetchLmdResult();

        // Then set up the interval to call the function every 5 seconds
        intervalId = setInterval(fetchLmdResult, 5000);

        // Cleanup function to clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, [lmdId]); // Empty dependency array means this effect runs only on component mount


    useEffect(() => {
        if(lmdOutput?.outputUrl) {
            fileServiceClient.fetchJsonData<LmdProcessOutput>(lmdOutput.outputUrl).then(setLmdRouteOutput);
        } else {
            setLmdRouteOutput(undefined);
        }
    }, [lmdOutput]);

    return (
         <ColumnLayout>
             <Container header={<Header headingTagOverride="h3">View Last Mile Delivery process status</Header>}>
                 <AbstractRouteOutputView output={lmdOutput}/>
             </Container>
             <Container header={<Header headingTagOverride="h3">View Last Mile Delivery process result</Header>}>
                 <ColumnLayout columns={2} variant="text-grid">
                     <SpaceBetween size="l">
                         <ValueWithLabel label="Total Distance Travelled">{lmdRouteOutput?.optimalRoute.objective.totalDistanceTravelled}</ValueWithLabel>
                         <ValueWithLabel label="Total Priority Cost">{lmdRouteOutput?.optimalRoute.objective.totalPriorityCost.toString()}</ValueWithLabel>
                         <ValueWithLabel label="Delivery Duration">{lmdRouteOutput?.optimalRoute.objective.maxDeliveryDuration.toString()}</ValueWithLabel>
                     </SpaceBetween>
                     <SpaceBetween size="l">
                         <ValueWithLabel label="First Point Arrival Time">
                             {lmdRouteOutput?.optimalRoute.deliveryRoute.legs[0].arrivalTime}
                         </ValueWithLabel>
                         <ValueWithLabel label="Last Point Arrival Time">
                             {lmdRouteOutput?.optimalRoute.deliveryRoute.legs[lmdRouteOutput?.optimalRoute.deliveryRoute.legs.length-1].arrivalTime}
                         </ValueWithLabel>
                     </SpaceBetween>
                 </ColumnLayout>
             </Container>
             {lmdRouteOutput && <LmdRouteOutputView input={lmdRouteOutput}/>}
         </ColumnLayout>
    )
}

export default LmdOutputView;