import {Coordinate} from "./Coordinate";

export type CombinedDeliveryPoint = DeliveryPoint | ClusteredDeliveryPoint;

export type DeliveryPoint = {
    coordinate: Coordinate;
    category: number;
    priority: number;
    clusterIndex: number;
    desi: number;
};

export type ClusteredDeliveryPoint = {
    coordinate: Coordinate;
    priority: number;
    clusterIndex: number;
    originalDeliveryPoints: DeliveryPoint[];
};

export function isClustered(value: CombinedDeliveryPoint): value is ClusteredDeliveryPoint {
    return (value as DeliveryPoint).category === undefined || (value as DeliveryPoint).category === null;
}

