import React from "react";
import {Badge, Box, ColumnLayout, ExpandableSection, Header, Link, SpaceBetween} from "@cloudscape-design/components";
import {downloadJson} from "../../../util/downloadUtil";
import {sampleLmdInput} from "./samples/SampleLmdInput";
import FileIcon from "../icon/FileIcon";
import {sampleLmdRouteOutput} from "./samples/SampleLmdRouteOutput";
import {sampleGetLmdOutput} from "./samples/SampleGetLmdOutput";
import {
    ClusteredDeliveryPointIntroductionView,
    CoordinateIntroductionView,
    DeliveryPointIntroductionView
} from "../compontents/common/CommonIntroductionTypes";

const PostOutputObject: React.FC = () => {
    return (
        <ColumnLayout columns={3} borders='all'>
            <h5>Property</h5>
            <h5>Description</h5>
            <h5>Example / Details</h5>
            <p>requestId</p>
            <p>The id of the lmd process record</p>
            <p>string</p>
        </ColumnLayout>
    );
}

const PostInputObject: React.FC = () => {
    return (
        <ColumnLayout columns={3} borders='all'>
            <h5>Property</h5>
            <h5>Description</h5>
            <h5>Example / Details</h5>
            <p>startCoordinate</p>
            <p>The coordinate where the routing must start from.</p>
            <p><Badge>Coordinate</Badge> object</p>
            <p>endCoordinate</p>
            <p>The coordinate where the routing must end.</p>
            <p><Badge>Coordinate</Badge> object</p>
            <p>deliveryPoints</p>
            <p>The points of deliveries, each with specific properties.</p>
            <p>Array of <Badge>DeliveryPoint</Badge> objects</p>
            <p style={{ marginLeft: '20px' }}>coordinate</p>
            <p>The coordinate of the delivery point.</p>
            <p><Badge>Coordinate</Badge> object</p>
            <p style={{ marginLeft: '20px' }}>category</p>
            <p>The category of the delivery, indicating specific characteristics like reception delay.</p>
            <p>"With reception" (allows setting category-specific latency in settings)</p>
            <p style={{ marginLeft: '20px' }}>priority</p>
            <p>A soft priority to influence delivery order without overriding clusterIndex.</p>
            <p>1 (higher numbers indicate higher priority)</p>
            <p style={{ marginLeft: '20px' }}>clusterIndex</p>
            <p>A hard priority that dictates the delivery order regardless of other objectives.</p>
            <p>0 (sequential order with lower numbers delivered first)</p>
            <p style={{ marginLeft: '20px' }}>desi</p>
            <p>Defines the Desi of the delivery, with options to set delivery time adjustments based on desi levels.</p>
            <p>0.05 (adds 10 seconds to delivery time if desi &lt; 0.1)</p>
            <p>startTime (optional)</p>
            <p>The start time of the delivery. Defaults to now if not provided.</p>
            <p>ISO 8601 string or undefined</p>
            <p>deliveryType</p>
            <p>Specifies the mode of delivery.</p>
            <p><Badge>PEDESTRIAN</Badge> or <Badge>MOTOR_VEHICLE</Badge></p>
        </ColumnLayout>
    );
}

const GetInputObject: React.FC = () => {
    return (
        <ColumnLayout columns={3} borders='all'>
            <h5>Property</h5>
            <h5>Description</h5>
            <h5>Example / Details</h5>
            <p>requestId</p>
            <p>The id returned by the Post request</p>
            <p>string</p>
        </ColumnLayout>
    );
}

const GetOutputObject: React.FC = () => {
    return (
        <ColumnLayout columns={3} borders='all'>
            <h5>Property</h5>
            <h5>Description</h5>
            <h5>Example / Details</h5>
            <p>id</p>
            <p>The id returned by the Post request</p>
            <p>string</p>
            <p>input</p>
            <p>The complete input in the Post request</p>
            <p><Badge>LmdInput</Badge> object</p>
            <p>status</p>
            <p>The status of the process</p>
            <p>[CREATED, PROCESSING, FINISHED, FAILED]</p>
            <p>ttl</p>
            <p>The time when we will delete the record from our database</p>
            <p>Epoch time</p>
            <p>nodeCount</p>
            <p>The number of deliveryPoints in the request</p>
            <p>number</p>
            <p>outputUrl</p>
            <p>[Optional] The url of the generated file if the status if FINISHED</p>
            <p>url containing json</p>
        </ColumnLayout>
    );
}

function GetHistoryInputObject() {
    return (
        <ColumnLayout columns={3} borders='all'>
            <h5>Property</h5>
            <h5>Description</h5>
            <h5>Example / Details</h5>
            <p>[Query Parameter] size</p>
            <p>The page size</p>
            <p>number</p>
            <p>[Query Parameter] lastId</p>
            <p>The last Id of the previous page</p>
            <p>string</p>
        </ColumnLayout>
    );
}

function LmdProcessResult() {
    return (
        <Box>
            <Badge>LmdProcessResult</Badge>
            <ColumnLayout columns={3} borders='all'>
                <h5>Property</h5>
                <h5>Description</h5>
                <h5>Example / Details</h5>
                <p>optimalRoute</p>
                <p>The optimal route for the last mile delivery request</p>
                <p>OptimalRoute object</p>
                <p style={{ marginLeft: '20px' }}>legs</p>
                <p>The legs of the route</p>
                <p>Array of BasicLeg objects</p>
                <p style={{ marginLeft: '40px' }}>deliveryPoint</p>
                <p>Information about the deliveryPoint</p>
                <p>Either <Badge>DeliveryPoint</Badge> or <Badge>ClusteredDeliveryPoint</Badge> object. Documented in the table below</p>
                <p style={{ marginLeft: '40px' }}>arrivalTime</p>
                <p>The arrival time to the delivery point.</p>
                <p>ISO 8601 string or undefined</p>
                <p style={{ marginLeft: '20px' }}>lmdObjective</p>
                <p>The objectives achieved in the optimization</p>
                <p><Badge>LmdObjective</Badge> object</p>
                <p style={{ marginLeft: '40px' }}>totalDistanceTravelled</p>
                <p>[Minimization] Total distance travelled during the complete delivery in KileMeters</p>
                <p>float</p>
                <p style={{ marginLeft: '40px' }}>totalPriorityCost</p>
                <p>[Minimization] The total priority cost of the route. Calculated by summing the priorities of
                    deliveryPoints multiplied by the delivery order grouped by clusterIndex</p>
                <p>integer</p>
            </ColumnLayout>
        </Box>
    );
}

const LastMileDeliveryIntroduction: React.FC = () => {

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '30px'}}>
            <Header variant='h1'>The Last Mile Delivery Application</Header>
            <Box>
                <p>This application is designed to optimize the last mile delivery problem,
                    ensuring that goods reach their destination efficiently and on time.
                    By inputting start and end coordinates, delivery points, and other relevant information,
                    users can plan the best delivery routes.</p>

                <p>The POST api works asynchronously, it gets the request and returns an id immediately. Then you can query
                    the GET api with the provided id. It will show the current status of the process. If it's IN_PROGRESS,
                    then you can query again shortly. The optimizer has 15 min timeout, so it will stay in the
                    IN_PROGRESS state 15 minutes at max. Eventually, you will either get COMPLETED or FAILED in the status.
                    The process which is in COMPLETED status, will return a url which you can download the results of the optimization.
                </p>

                <p>The input and output of the api is explained below. You can download
                    <ul>
                        <li><Link onFollow={(e) => downloadJson('SampleLmdInput.json', sampleLmdInput)}>The sample input<FileIcon/></Link>
                            file which you can use to start an Lmd process by calling [POST] /lmd operation.
                        </li>
                        <li><Link onFollow={(e) =>
                            downloadJson('SampleLmdOutput.json', sampleGetLmdOutput)}>The sample output<FileIcon/></Link> of the [GET] /lmd/{"{requestId}"} operation.
                        </li>
                        <li><Link onFollow={(e) =>
                            downloadJson('SampleLmdProcessOutput.json', sampleLmdRouteOutput)}>The optimization result file<FileIcon/></Link> as part of the process output if the status of the process is FINISHED.
                        </li>
                    </ul>

                </p>
            </Box>
            <Header variant='h1'>APIs Explained</Header>

            <ExpandableSection headerText={`[POST] ${process.env.REACT_APP_API_URL}/lmd`}>
                <Header variant='h2' >Request</Header>
                <PostInputObject/>
                <Header variant='h2' >Response</Header>
                <PostOutputObject/>
            </ExpandableSection>

            <ExpandableSection headerText={`[GET] ${process.env.REACT_APP_API_URL}/lmd/{requestId}`}>
                <Header variant='h2' >Request</Header>
                <GetInputObject/>
                <Header variant='h2' >Response</Header>
                <GetOutputObject/>
            </ExpandableSection>

            <ExpandableSection headerText={`[GET] ${process.env.REACT_APP_API_URL}/lmd/history`} >
                <SpaceBetween size='xl'>
                    <Header variant='h2' >Request</Header>
                    <GetHistoryInputObject/>
                    <Header variant='h2' >Response</Header>
                    <p>List of <Badge>LmdOutput</Badge> objects</p>
                    <GetOutputObject/>
                </SpaceBetween>
            </ExpandableSection>

            <Header variant='h1'>Other Types</Header>

            <ExpandableSection headerText={'Lmd Process Result'} >
                <LmdProcessResult/>
            </ExpandableSection>

            <ExpandableSection headerText={'DeliveryPoint types'} >
                <DeliveryPointIntroductionView/>
                <ClusteredDeliveryPointIntroductionView/>
            </ExpandableSection>

            <ExpandableSection headerText={'Common Types'} >
                <CoordinateIntroductionView/>
            </ExpandableSection>
        </div>
    );
};

export default LastMileDeliveryIntroduction;