import React, {PropsWithChildren} from "react";
import {Amplify} from "aws-amplify";
import {Authenticator} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: `${process.env.REACT_APP_USER_POOL_ID}`,
      userPoolClientId: `${process.env.REACT_APP_USER_POOL_CLIENT_ID}`,
    },
  },
});

// Define the props interface if you need to accept additional props
interface AuthenticationWrapperProps {
  // Example of an additional prop; you can add as many as you need
  title?: string;
}

export const AuthenticationWrapper: React.FC<
  PropsWithChildren<AuthenticationWrapperProps>
> = ({ children }) => {
  return (
    <Authenticator
      initialState="signIn"
      components={{
        SignUp: {
          FormFields() {
            return <SignUpForm />;
          },
        },
      }}
    >
      {() => <>{children}</>}
    </Authenticator>
  );
};

const SignUpForm = () => {
  return (
    <>
      <Authenticator.SignUp.FormFields />
      <div>
        <label>Name</label>
      </div>
      <input
        type="text"
        name="name"
        required={true}
        placeholder="Please enter your name"
      />
      <div>
        <label>Email</label>
      </div>
      <input
        type="text"
        name="email"
        required={true}
        placeholder="Please enter a valid email"
      />
      <div>
        <label>Company</label>
      </div>
      <input
        type="text"
        name="custom:company"
        placeholder="Please enter your company"
      />
        <div>
            <label>Apikey</label>
        </div>
        <input
            type="text"
            name="custom:apikey"
            placeholder="Please enter your apikey"
        />
    </>
  );
};
