import React, {useEffect, useState} from "react";
import {useApiClient} from "../../../client/OptimaSoftClient";
import {useUser} from "../../../context/UserContext";
import {LmdConfiguration} from "../../../types/route/lmd/LmdConfiguration";
import {Product} from "../../../types/route/Configuration";
import {Box, Button, Flashbar, Form, Header, SpaceBetween, Spinner} from "@cloudscape-design/components";
import {MapInputComponent} from "../../../components/input/MapInputComponent";

const LmdConfigurationComponent : React.FC<{configuration: LmdConfiguration, updateConfiguration: (configuration: LmdConfiguration) => void}> = ({configuration, updateConfiguration}) => {
    const [categoryMap, setCategoryMap] = useState<{ [key: number]: number }>(Object.fromEntries(Object.entries(configuration.categoryDeliveryDurationMap).map(([key, value]) => [Number(key), value])));
    const [desiMap, setDesiMap] = useState<{ [key: number]: number }>(Object.fromEntries(Object.entries(configuration.desiDeliveryLatencyMap).map(([key, value]) => [Number(key), value])));

    const onClick = () => updateConfiguration({product: configuration.product, desiDeliveryLatencyMap: desiMap, categoryDeliveryDurationMap: categoryMap});

    return (
        <form onSubmit={(e) => e.preventDefault()}>
            <Form actions={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" onClick={onClick}>
                        Update Configuration
                    </Button>
                </SpaceBetween>
            }>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '30px'}}>
                    <Box>
                        <Header variant='h3'>Desi Latency Map</Header>
                        <p>The DESI (Dimensional Size Index) is a measurement used to calculate the volumetric or dimensional weight of a package,
                            which is then used to determine the latency of the delivery. You are free to use any logic on calculating the desi,
                            this map helps you mapping your calculation of the size of the delivery to the latency it may cause while the delivery.</p>
                        <p>The type of the desi values are float and each desi value covers all the desi values bigger than itself. I.e. consider the map
                            is configured as:
                            <ul>
                                <li>desi 0.5 causes 10 seconds latency</li>
                                <li>desi 3 causes 30 seconds latency</li>
                                <li>desi 20 causes 60 seconds latency</li>
                            </ul>
                            Then we take the latencies in our calculations as follows:
                            <ul>
                                <li>0 seconds latency if the desi of the delivery is between [0, 0.5)</li>
                                <li>10 seconds latency if the desi of the delivery is between [0.5, 3.0)</li>
                                <li>30 seconds latency if the desi of the delivery is between [3.0, 20.0)</li>
                                <li>60 seconds latency if the desi of the delivery is between [20.0, INF)</li>
                            </ul>
                        </p>
                    </Box>
                    <MapInputComponent map={desiMap} setMap={setDesiMap} keyLabel={'Desi'} keyDescription={'Represents the desi value of the delivery'} valueLabel={'Desi based delivery latency in seconds'} valueDescription={'Represents the latency for the desi value equal to the given desi and the flooring desi value in the map.'}/>
                    <Box>
                        <Header variant='h3'>Category Latency Map</Header>
                        <p>The category indicates the type of the delivery, i.e. post box delivery, delivery with signature,
                            delivery with reception. Each of them has it's own challenges and cause different latencies.</p>
                        <p>The type of the category is integer and each category covers all the category values bigger than itself. I.e. consider the map
                            is configured as:
                            <ul>
                                <li>category 1 causes 10 seconds latency</li>
                                <li>category 5 causes 50 seconds latency</li>
                                <li>category 10 causes 100 seconds latency</li>
                            </ul>
                            Then we take the latencies in our calculations as follows:
                            <ul>
                                <li>0 seconds latency if the category of the delivery is between [0, 1)</li>
                                <li>10 seconds latency if the category of the delivery is between [1, 5)</li>
                                <li>50 seconds latency if the category of the delivery is between [5, 10)</li>
                                <li>100 seconds latency if the category of the delivery is between [10, INF)</li>
                            </ul>
                        </p>
                    </Box>
                    <MapInputComponent map={categoryMap} setMap={setCategoryMap} keyLabel={'Category'} keyDescription={'Represents the category of the delivery'} valueLabel={'Category based delivery latency in seconds'} valueDescription={'Represents the latency for the category value equal to the given category and the ceiling category value in the map.'}/>
                </div>
            </Form>
        </form>
    );
}

const LastMileDeliveryConfiguration : React.FC = () => {
    const {configurationClient} = useApiClient();
    const {apiKey} = useUser();
    const [configuration, setConfiguration] = useState<LmdConfiguration>();
    const [showSuccessBanner, setShowSuccessBanner] = useState<boolean>(false);
    useEffect(() => {
        configurationClient.getConfiguration<LmdConfiguration>(apiKey, Product.LMD).then(configuration => setConfiguration(configuration.input));
    }, []);
    const updateConfiguration = (configuration: LmdConfiguration) => {
        configurationClient.postConfiguration(apiKey, configuration).then(c => setShowSuccessBanner(true));
    }

    if(!configuration) {
        return <Spinner/>
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '30px'}}>
            <Header variant='h1'>Configuration for the Last Mile Delivery Application</Header>
            <Box>
                <p>This application requires a few high level configurations which we will use while running every request.</p>

                <p>We have in total of 2 configurations to set: <b>categoryDeliveryDurationMap</b> and <b>desiDeliveryLatencyMap</b></p>

                <LmdConfigurationComponent configuration={configuration} updateConfiguration={updateConfiguration}/>

                {showSuccessBanner && <Flashbar items={[{
                    id: 'success-flashbar',
                    type: 'success',
                    dismissible: true,
                    dismissLabel: 'Dismiss',
                    onDismiss: () => setShowSuccessBanner(false),
                    content: (
                        <>
                            The configuration has been <strong>successfully</strong> updated.
                        </>
                    )
                }]}/>}
            </Box>
        </div>
    );
}

export default LastMileDeliveryConfiguration;