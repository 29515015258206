export class Stack<T> {
  private container: T[] = [];

  // Pushes an item onto the stack
  public push(item: T): void {
    this.container.push(item);
  }

  // Pops the top item off the stack and returns it. Returns undefined if the stack is empty.
  public pop(): T | undefined {
    return this.container.pop();
  }

  // Returns the top item from the stack without removing it. Returns undefined if the stack is empty.
  public peek(): T | undefined {
    return this.container[this.container.length - 1];
  }

  // Returns true if the stack is empty, false otherwise
  public isEmpty(): boolean {
    return this.container.length === 0;
  }

  // Returns the size of the stack
  public size(): number {
    return this.container.length;
  }

  // Clears the stack
  public clear(): void {
    this.container = [];
  }
}