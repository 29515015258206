import React from "react";
import {Badge, Box, ColumnLayout, ExpandableSection, Header, Link, SpaceBetween} from "@cloudscape-design/components";
import FileIcon from "../icon/FileIcon";
import {sampleLmdRouteOutput} from "../lastmiledelivery/samples/SampleLmdRouteOutput";
import {downloadJson} from "../../../util/downloadUtil";
import {sampleGetLmdOutput} from "../lastmiledelivery/samples/SampleGetLmdOutput";
import {sampleDistanceMatrixInput} from "./samples/SampleDistanceMatrixInput";
import {sampleDistanceMatrixOutput} from "./samples/SampleDistanceMatrixOutput";
import {sampleDistanceMatrixProcessOutput} from "./samples/SampleDistanceMatrixProcessOutput";
import {CoordinateIntroductionView} from "../compontents/common/CommonIntroductionTypes";

const PostInputObject: React.FC = () => {
    return (
        <ColumnLayout columns={3} borders='all'>
            <h5>Property</h5>
            <h5>Description</h5>
            <h5>Example / Details</h5>
            <p>coordinates</p>
            <p>The coordinates of the distance matrix</p>
            <p>list of <Badge>Coordinate</Badge> objects</p>
        </ColumnLayout>
    );
}

const PostOutputObject: React.FC = () => {
    return (
        <ColumnLayout columns={3} borders='all'>
            <h5>Property</h5>
            <h5>Description</h5>
            <h5>Example / Details</h5>
            <p>requestId</p>
            <p>The id of the distance matrix process record</p>
            <p>string</p>
        </ColumnLayout>
    );
}

const GetOutputObject: React.FC = () => {
    return (
        <ColumnLayout columns={3} borders='all'>
            <h5>Property</h5>
            <h5>Description</h5>
            <h5>Example / Details</h5>
            <p>id</p>
            <p>The id returned by the Post request</p>
            <p>string</p>
            <p>input</p>
            <p>The complete input in the Post request</p>
            <p><Badge>DistanceMatrixInput</Badge> object</p>
            <p>status</p>
            <p>The status of the process</p>
            <p>[CREATED, PROCESSING, FINISHED, FAILED]</p>
            <p>ttl</p>
            <p>The time when we will delete the record from our database</p>
            <p>Epoch time</p>
            <p>nodeCount</p>
            <p>The number of coordinates in the request</p>
            <p>number</p>
            <p>outputUrl</p>
            <p>[Optional] The url of the generated file if the status if FINISHED</p>
            <p>url containing json</p>
        </ColumnLayout>
    );
}

const GetInputObject: React.FC = () => {
    return (
        <ColumnLayout columns={3} borders='all'>
            <h5>Property</h5>
            <h5>Description</h5>
            <h5>Example / Details</h5>
            <p>requestId</p>
            <p>The id returned by the Post request</p>
            <p>string</p>
        </ColumnLayout>
    );
}

function GetHistoryInputObject() {
    return (
        <ColumnLayout columns={3} borders='all'>
            <h5>Property</h5>
            <h5>Description</h5>
            <h5>Example / Details</h5>
            <p>[Query Parameter] size</p>
            <p>The page size</p>
            <p>number</p>
            <p>[Query Parameter] lastId</p>
            <p>The last Id of the previous page</p>
            <p>string</p>
        </ColumnLayout>
    );
}

function DistanceMatrixProcessResult() {
    return (
        <ColumnLayout columns={3} borders='all'>
            <h5>Property</h5>
            <h5>Description</h5>
            <h5>Example / Details</h5>
            <p>coordinates</p>
            <p>List of coordinates</p>
            <p><Badge>Coordinate</Badge> objects</p>
            <p style={{ marginLeft: '20px' }}>distanceMatrix</p>
            <p>The NxN distance matrix consist of distances across the given coordinates</p>
            <p>integer distance in meters</p>
        </ColumnLayout>
    );
}

function Introduction() {
    return (
        <Box>
            <p>This application is designed to calculate the distance matrix accros the given list of coordinates.
                It reproduces NxN matrix in the same order as the input coordinates in each dimension. The items with
                the same index [n,n] are always 0. This api doesn't cluster the coordinates and doesn't filter the duplicates.
            </p>
            <p>The POST api works asynchronously, it gets the request and returns an id immediately. Then you can query
                the GET api with the provided id. It will show the current status of the process. If it's IN_PROGRESS,
                then you can query again shortly. The distance calculator has 15 min timeout, so it will stay in the
                IN_PROGRESS state 15 minutes at max. Eventually, you will either get COMPLETED or FAILED in the status.
                The process which is in COMPLETED status, will return a url where you can download the results of the operation.
            </p>

            <p>The input and output of the api is explained below. You can download
                <ul>
                    <li><Link onFollow={(e) => downloadJson('SampleDistanceMatrixInput.json', sampleDistanceMatrixInput)}>The sample input<FileIcon/></Link>
                        file which you can use to start an Lmd process by calling [POST] /distance operation.
                    </li>
                    <li><Link onFollow={(e) =>
                        downloadJson('SampleDistanceMatrixOutput.json', sampleDistanceMatrixOutput)}>The sample output<FileIcon/></Link> of the [GET] /distance/{"{requestId}"} operation.
                    </li>
                    <li><Link onFollow={(e) =>
                        downloadJson('SampleDistanceMatrixProcessOutput.json', sampleDistanceMatrixProcessOutput)}>The distance matrix result file<FileIcon/></Link> as part of the process output if the status of the process is FINISHED.
                    </li>
                </ul>

            </p>
        </Box>
    );
}

const DistanceMatrixIntroduction: React.FC = () => {

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '30px'}}>
            <Header variant='h1'>The Distance Matrix Application</Header>
            <Introduction/>
            <Header variant='h1'>APIs Explained</Header>

            <ExpandableSection headerText={`[POST] ${process.env.REACT_APP_API_URL}/distance`}>
                <SpaceBetween size='xl'>
                    <Header variant='h2' >Request</Header>
                    <PostInputObject/>
                    <Header variant='h2' >Response</Header>
                    <PostOutputObject/>
                </SpaceBetween>
            </ExpandableSection>

            <ExpandableSection headerText={`[GET] ${process.env.REACT_APP_API_URL}/distance/{requestId}`}>
                <SpaceBetween size='xl'>
                    <Header variant='h2' >Request</Header>
                    <GetInputObject/>
                    <Header variant='h2' >Response</Header>
                    <GetOutputObject/>
                </SpaceBetween>
            </ExpandableSection>

            <ExpandableSection headerText={`[GET] ${process.env.REACT_APP_API_URL}/distance/history`} >
                <SpaceBetween size='xl'>
                    <Header variant='h2' >Request</Header>
                    <GetHistoryInputObject/>
                    <Header variant='h2' >Response</Header>
                    <p>List of <Badge>DistanceMatrixOutput</Badge> objects</p>
                    <GetOutputObject/>
                </SpaceBetween>
            </ExpandableSection>

            <Header variant='h1'>Other Types</Header>

            <ExpandableSection headerText={'Lmd Process Result'} >
                <Box>
                    <Badge>LmdProcessResult</Badge>
                    <DistanceMatrixProcessResult/>
                </Box>
            </ExpandableSection>

            <ExpandableSection headerText={'Common Types'} >
                <CoordinateIntroductionView/>
            </ExpandableSection>
        </div>
    );
};

export default DistanceMatrixIntroduction;