import React, {useEffect, useState} from "react";
import {ColumnLayout, Container, Header, SpaceBetween} from "@cloudscape-design/components";
import ValueWithLabel from "../../../../components/ValueWithLabel";
import {useUser} from "../../../../context/UserContext";
import {useApiClient} from "../../../../client/OptimaSoftClient";
import {useFileServiceClient} from "../../../../client/FileServiceClient";
import {ProcessStatus} from "../../../../types/route/ProcessStatus";
import {AbstractRouteOutputView} from "../../compontents/AbstractRouteOutputView";
import {GetMlmdOutput} from "../../../../types/route/mlmd/GetMlmdOutput";
import {MlmdProcessOutput} from "../../../../types/route/mlmd/MlmdProcessOutput";
import MlmdRouteOutputView from "./MlmdRouteOutputView";

type MlmdOutputViewProps = {
    mlmdId: string
};

type MlmdRouteOutputStats = {
    earliestTime: string,
    latestTime: string
};

const MlmdOutputView : React.FC<MlmdOutputViewProps> = ({mlmdId}) => {
    const [isOptimizationProcessing, setIsOptimizationProcessing] = useState(false);
    const {apiKey} = useUser();
    const {mlmdClient} = useApiClient();
    const [mlmdOutput, setMlmdOutput] = useState<GetMlmdOutput|undefined>();
    const [mlmdRouteOutput, setMlmdRouteOutput] = useState<MlmdProcessOutput|undefined>();
    const [mlmdRouteOutputStats, setMlmdRouteOutputStats] = useState<MlmdRouteOutputStats|undefined>();
    const fileServiceClient = useFileServiceClient();

    useEffect(() => {
        let intervalId: NodeJS.Timer;

        const fetchMlmdResult = async () => {
            if(!mlmdId || !apiKey) {
                return;
            }
            setIsOptimizationProcessing(true);

            // Simulate the API call
            const response: GetMlmdOutput = await mlmdClient.getMlmdResult(apiKey, mlmdId);

            if(response.status === ProcessStatus.FINISHED || response.status === ProcessStatus.FAILED) {
                clearInterval(intervalId);
                setIsOptimizationProcessing(false);
            }

            setMlmdOutput(response);
        };

        // Call the function immediately on component mount
        fetchMlmdResult();

        // Then set up the interval to call the function every 5 seconds
        intervalId = setInterval(fetchMlmdResult, 5000);

        // Cleanup function to clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, [mlmdId]); // Empty dependency array means this effect runs only on component mount


    useEffect(() => {
        if(mlmdOutput?.outputUrl) {
            fileServiceClient.fetchJsonData<MlmdProcessOutput>(mlmdOutput.outputUrl).then(setMlmdRouteOutput);
        } else {
            setMlmdRouteOutput(undefined);
        }
    }, [mlmdOutput]);

    useEffect(() => {
        if(mlmdOutput) {
            setMlmdRouteOutputStats(
                mlmdRouteOutput?.optimalRoute.deliveryRoutes.map(dr => dr.legs).flat().reduce(({ earliestTime, latestTime }, leg) => ({
                earliestTime: earliestTime < leg.arrivalTime ? earliestTime : leg.arrivalTime,
                latestTime: latestTime > leg.arrivalTime ? latestTime : leg.arrivalTime,
                }), { earliestTime: '9999-12-31T23:59:59Z', latestTime: '0000-01-01T00:00:00Z' }));
        }
    }, [mlmdRouteOutput]);

    return (
        <ColumnLayout>
            <Container header={<Header headingTagOverride="h3">View Last Mile Delivery process status</Header>}>
                <AbstractRouteOutputView output={mlmdOutput}/>
            </Container>
            <Container header={<Header headingTagOverride="h3">View Last Mile Delivery process result</Header>}>
                <ColumnLayout columns={2} variant="text-grid">
                    <SpaceBetween size="l">
                        <ValueWithLabel label="Total Distance Travelled">{mlmdRouteOutput?.optimalRoute.objective.totalDistanceTravelled}</ValueWithLabel>
                        <ValueWithLabel label="Total Priority Cost">{mlmdRouteOutput?.optimalRoute.objective.totalPriorityCost.toString()}</ValueWithLabel>
                        <ValueWithLabel label="Max Distance Per Courier">{mlmdRouteOutput?.optimalRoute.objective.maxDistancePerCourier.toString()}</ValueWithLabel>
                        <ValueWithLabel label="Max Duration Per Courier">{mlmdRouteOutput?.optimalRoute.objective.maxDurationPerCourier.toString()}</ValueWithLabel>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                        <ValueWithLabel label="First Point Arrival Time">
                            {mlmdRouteOutputStats?.earliestTime}
                        </ValueWithLabel>
                        <ValueWithLabel label="Last Point Arrival Time">
                            {mlmdRouteOutputStats?.latestTime}
                        </ValueWithLabel>
                    </SpaceBetween>
                </ColumnLayout>
            </Container>
            {mlmdRouteOutput && <MlmdRouteOutputView input={mlmdRouteOutput}/>}
        </ColumnLayout>
    )
}

export default MlmdOutputView;