import React, {useEffect, useState} from "react";
import {Button, ColumnLayout, Container, Header, Modal, SpaceBetween, Table} from "@cloudscape-design/components";
import {useUser} from "../../../../context/UserContext";
import {useApiClient} from "../../../../client/OptimaSoftClient";
import {useFileServiceClient} from "../../../../client/FileServiceClient";
import {ProcessStatus} from "../../../../types/route/ProcessStatus";
import {GetDistanceMatrixOutput} from "../../../../types/route/distancematrix/GetDistanceMatrixOutput";
import {DistanceMatrixProductOutput} from "../../../../types/route/distancematrix/DistanceMatrixProductOutput";
import {AbstractRouteOutputView} from "../../compontents/AbstractRouteOutputView";

type DistanceMatrixOutputViewProps = {
    distanceMatrixId: string
};

const DistanceMatrixOutputView : React.FC<DistanceMatrixOutputViewProps> = ({distanceMatrixId}) => {
    const [isOptimizationProcessing, setIsOptimizationProcessing] = useState(false);
    const {apiKey} = useUser();
    const {distanceMatrixClient} = useApiClient();
    const [distanceMatrixOutput, setDistanceMatrixOutput] = useState<GetDistanceMatrixOutput|undefined>();
    const [distanceMatrixProductOutput, setDistanceMatrixProductOutput] = useState<DistanceMatrixProductOutput|undefined>();
    const fileServiceClient = useFileServiceClient();
    const [requestFileVisible, setRequestFileVisible] = React.useState(false);

    useEffect(() => {
        let intervalId: NodeJS.Timer;

        const fetchLmdResult = async () => {
            if(!distanceMatrixId || !apiKey) {
                return;
            }
            setIsOptimizationProcessing(true);

            // Simulate the API call
            const response: GetDistanceMatrixOutput = await distanceMatrixClient.getDistanceMatrixResult(apiKey, distanceMatrixId);

            if(response.status === ProcessStatus.FINISHED || response.status === ProcessStatus.FAILED) {
                clearInterval(intervalId);
                setIsOptimizationProcessing(false);
            }

            setDistanceMatrixOutput(response);
        };

        // Call the function immediately on component mount
        fetchLmdResult();

        // Then set up the interval to call the function every 5 seconds
        intervalId = setInterval(fetchLmdResult, 5000);

        // Cleanup function to clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, [distanceMatrixId]); // Empty dependency array means this effect runs only on component mount


    useEffect(() => {
        if(distanceMatrixOutput?.outputUrl) {
            fileServiceClient.fetchJsonData<DistanceMatrixProductOutput>(distanceMatrixOutput.outputUrl).then(setDistanceMatrixProductOutput);
        } else {
            setDistanceMatrixProductOutput(undefined);
        }
    }, [distanceMatrixOutput]);

    return (
         <ColumnLayout>
             <Container header={<Header headingTagOverride="h3">View Distance Matrix process status</Header>}>
                 <AbstractRouteOutputView output={distanceMatrixOutput}/>
             </Container>
             {distanceMatrixProductOutput &&
                 <Container>
                     <SpaceBetween size='m'>
                     <Button onClick={() => setRequestFileVisible(true)}>
                         Preview Input Coordinates
                     </Button>
                     <Modal
                         onDismiss={() => setRequestFileVisible(false)}
                         visible={requestFileVisible}
                         header="Distance Matrix Input Coordinates Preview">
                            <pre style={{ whiteSpace: 'pre', wordBreak: 'break-word', margin: '0', padding: '0.5rem' , overflowX: 'scroll'}}>
                                {JSON.stringify(distanceMatrixProductOutput.coordinates, null, 2)}
                            </pre>
                     </Modal>
                     <Header headingTagOverride="h4">View Resulting Distance Matrix</Header>
                     <Table items={distanceMatrixProductOutput.distanceMatrix.map((innerArr, index) => [index, ...innerArr])}
                            loadingText="Loading Distance Matrix"
                            columnDefinitions={[' ', ...distanceMatrixProductOutput.coordinates.map((_, index) => index.toString())]
                                .map( (title, index) => {
                                    return {
                                        id: title,
                                        header: title,
                                        cell: (item: number[]) => item[index],
                                        isRowHeader: true
                                    };
                                })}
                         />
                     </SpaceBetween>
                 </Container>
             }
         </ColumnLayout>
    )
}

export default DistanceMatrixOutputView;