import React from "react";
import {useTabContext} from "../lastmiledelivery/context/TabContext";
import {Tabs} from "@cloudscape-design/components";
import DistanceMatrixIntroduction from "./DistanceMatrixIntroduction";
import DistanceMatrixHistory from "./DistanceMatrixHistory";
import DistanceMatrixNewRequest from "./DistanceMatrixNewRequest";

const DistanceMatrixTab: React.FC = () => {
    const {activeTabId, setActiveTabId} = useTabContext();
    return (
        <Tabs
            tabs={[
                {
                    label: "Introduction",
                    id: "introduction",
                    content: <DistanceMatrixIntroduction/>
                },
                {
                    label: "New Request",
                    id: "new-request",
                    content: <DistanceMatrixNewRequest/>
                },
                {
                    label: "History",
                    id: "history",
                    content: <DistanceMatrixHistory/>
                }
            ]} onChange={(e) => setActiveTabId(e.detail.activeTabId)} activeTabId={activeTabId}/>
    );
};

export default DistanceMatrixTab;