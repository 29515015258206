import React from "react";
import {AbstractRouteOutput} from "../../../types/route/AbstractRouteOutput";
import {ColumnLayout, SpaceBetween} from "@cloudscape-design/components";
import ValueWithLabel from "../../../components/ValueWithLabel";
import ProcessStatusIndicator from "../../../components/ProcessStatusIndicator";

export const AbstractRouteOutputView: React.FC<{output: AbstractRouteOutput<any>|undefined}> = ({output}) => {
    return (
        <ColumnLayout columns={3} variant="text-grid">
            <SpaceBetween size="l">
                <ValueWithLabel label="Request Id">{output?.id}</ValueWithLabel>
                <ValueWithLabel label="Status">
                    <ProcessStatusIndicator status={output?.status} error={output?.failReason} />
                </ValueWithLabel>
            </SpaceBetween>
            <SpaceBetween size="l">
                <ValueWithLabel label="Process Duration">
                    {output?.processDuration}
                </ValueWithLabel>
                <ValueWithLabel label="Node Count">
                    {output?.nodeCount}
                </ValueWithLabel>
            </SpaceBetween>
            <SpaceBetween size="l">
                <ValueWithLabel label="Time To Live">
                    {output?.ttl}
                </ValueWithLabel>
            </SpaceBetween>
        </ColumnLayout>
    );
}