import React, {PropsWithChildren} from "react";
import {AppLayout, BreadcrumbGroup, SideNavigation} from "@cloudscape-design/components";
import {SideNavigationProps} from "@cloudscape-design/components/side-navigation/interfaces";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {UserGroup, useUser} from "../context/UserContext";

const productMap: {[key: string] : {text: string, href: string}[]} = {
    'Route' : [
        {text: 'Distance Matrix', href: '/distance-matrix'},
        {text: 'Last Mile Delivery', href: '/last-mile-delivery'},
        {text: 'Multi Last Mile Delivery', href: '/multi-last-mile-delivery'}
    ]};

const convertPathToBreadcrumbs = (path: string) => {
    const segments = path.split('/').filter(Boolean); // Split and remove any empty segments
    return segments.map((current, index, array) => {
        const href = '/' + array.slice(0, index + 1).join('/'); // Build the href for the current segment
        const text = current.replace(/-/g, ' ').replace(/\b\w/g, letter => letter.toUpperCase()); // Replace dashes with spaces and capitalize
        return { text, href };
    });
};

const adminSideNavigationPropsItem : SideNavigationProps.Item = {
    type: 'link',
    text: 'Admin',
    href: '/admin'
};

const ApplicationLayout : React.FC<PropsWithChildren> = ({children}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { groups } = useUser();

    const navItems: ReadonlyArray<SideNavigationProps.Item> = Object.keys(productMap).map(productGroup => {
       return {
            type: 'section',
            text: productGroup,
            items: productMap[productGroup].map(product => {return {
                type: 'link',
                ...product
            }})
        }
    })

    const allItems = groups?.has(UserGroup.ADMIN) ? [adminSideNavigationPropsItem, ...navItems] : navItems;

    return (
        <AppLayout
            stickyNotifications
            toolsHide
            navigation={<SideNavigation items={allItems} onFollow={(e) => {e.preventDefault(); navigate(e.detail.href);}}/>}
            breadcrumbs={<BreadcrumbGroup items={convertPathToBreadcrumbs(location.pathname)} expandAriaLabel="Show path" ariaLabel="Breadcrumbs" />}
            content={<Outlet/>}/>
    );
}

export default ApplicationLayout;