import React, {useEffect, useState} from "react";
import {Stack} from "../../../util/Stack";
import {GetLmdOutput} from "../../../types/route/lmd/GetLmdOutput";
import {useApiClient} from "../../../client/OptimaSoftClient";
import {useUser} from "../../../context/UserContext";
import {Pagination, Table} from "@cloudscape-design/components";
import LmdOutputView from "./components/LmdOutputView";
import {DATE_TIME_FORMAT_OPTIONS} from "../../../util/timeUtil";

type CurrentPage = {
    previousIdStack: Stack<string>,
    pageIndex: number,
    pageSize: number,
    isLastPage: boolean,
    currentItems: GetLmdOutput[],
    selectedItem?: GetLmdOutput,
    currentId?: string,
    nextId?: string,
}

const LastMileDeliveryHistory : React.FC = () => {
    const { lmdClient } = useApiClient();
    const {apiKey} = useUser();
    const [currentPage, setCurrentPage] = useState<CurrentPage>({previousIdStack: new Stack<string>(), pageIndex: 0, isLastPage: false, pageSize: 25, currentItems: []});
    const [selectedItem, setSelectedItem] = useState<GetLmdOutput|undefined>();

    const loadPreviousPage = (currentPage: CurrentPage) => {
        const previousId = currentPage.previousIdStack.pop();
        lmdClient.getLmdHistory(apiKey, currentPage.pageSize, previousId).then(lmdHistory => {
            setCurrentPage({
                ...currentPage,
                pageIndex: currentPage.pageIndex-1,
                currentId: previousId,
                isLastPage: false,
                nextId: currentPage.currentId,
                currentItems: lmdHistory
            });
        });
    }

    const loadNextPage = (currentPage: CurrentPage) => {
        lmdClient.getLmdHistory(apiKey, currentPage.pageSize, currentPage.nextId).then(lmdHistory => {
            if(lmdHistory.length === 0){
                setCurrentPage({
                    ...currentPage,
                    isLastPage: true,
                });
                return;
            }
            const lastId = lmdHistory[lmdHistory.length - 1].id;
            currentPage.currentId && currentPage.previousIdStack.push(currentPage.currentId);
            setCurrentPage({
                ...currentPage,
                pageIndex: currentPage.pageIndex+1,
                currentId: currentPage.nextId,
                isLastPage: false,
                nextId: lastId,
                currentItems: lmdHistory
            });
        });
    }

    useEffect(() => {
        loadNextPage(currentPage);
    }, []);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '30px'}}>
            <Table items={currentPage.currentItems}
                   onSelectionChange={({ detail }) =>
                   {
                       setSelectedItem(detail.selectedItems[0]);
                   }}
                   selectedItems={selectedItem ? [selectedItem] : []}
                   selectionType='single'
                   loadingText="Loading historical Lmd requests"
                   pagination={
                       <Pagination currentPageIndex={currentPage.pageIndex}
                                   pagesCount={currentPage.currentItems.length < currentPage.pageSize || currentPage.isLastPage ? currentPage.pageIndex : currentPage.pageIndex + 1}
                                   onPreviousPageClick={e => loadPreviousPage(currentPage)}
                                   onNextPageClick={e => loadNextPage(currentPage)}
                       />
                   }
                   columnDefinitions={[
                       {id: 'id', header: 'Id', cell: item => item.id, sortingField: 'id', isRowHeader: true},
                       {id: 'creationTime', header: 'CreationTime', cell: item => new Date(item.creationTime).toLocaleDateString(undefined, DATE_TIME_FORMAT_OPTIONS), sortingField: 'creationTime'},
                       {id: 'startPoint', header: 'StartPoint', cell: item => <p>Lat: {item.input.courier.startCoordinate.latitude} Lon: {item.input.courier.startCoordinate.longitude}</p>, sortingField: 'StartPoint'},
                       {id: 'endPoint', header: 'EndPoint', cell: item => <p>Lat: {item.input.courier.endCoordinate.latitude} Lon: {item.input.courier.endCoordinate.longitude}</p>, sortingField: 'EndPoint'},
                       {id: 'nodeCount', header: 'NodeCount', cell: item => item.nodeCount, sortingField: 'NodeCount'},
                       {id: 'deliveryType', header: 'DeliveryType', cell: item => item.input.courier.deliveryType, sortingField: 'DeliveryType'},
                       {id: 'startTime', header: 'StartTime', cell: item => item.input.courier.startTime.toString(), sortingField: 'StartTime'},
                       {id: 'status', header: 'Status', cell: item => item.status, sortingField: 'Status'},
                       {id: 'processDuration', header: 'ProcessDuration', cell: item => item.processDuration, sortingField: 'ProcessDuration'},
                       {id: 'ttl', header: 'Ttl', cell: item => new Date(item.ttl).toLocaleDateString(undefined, DATE_TIME_FORMAT_OPTIONS), sortingField: 'Ttl'},
                       {id: 'failReason', header: 'FailReason', cell: item => item.failReason, sortingField: 'FailReason'}]}/>
            {selectedItem && <LmdOutputView lmdId={selectedItem?.id}/>}
        </div>
    );
}

export default LastMileDeliveryHistory;