import React from "react";
import {useTabContext} from "../lastmiledelivery/context/TabContext";
import {Tabs} from "@cloudscape-design/components";
import MapIntegrityCheck from "./MapIntegrityCheck";

const AdminTabs: React.FC = () => {
    const {activeTabId, setActiveTabId} = useTabContext();
    return (
        <Tabs
            tabs={[
                {
                    label: "Map Integrity",
                    id: "map-integrity",
                    content: <MapIntegrityCheck/>
                }
            ]} onChange={(e) => setActiveTabId(e.detail.activeTabId)} activeTabId={activeTabId}/>
    );
};
export default AdminTabs;