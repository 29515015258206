import {PostProductResponse} from "../../types/route/PostProductResponse";
import {MlmdInput} from "../../types/route/mlmd/MlmdInput";
import {GetMlmdOutput} from "../../types/route/mlmd/GetMlmdOutput";

export class MlmdClient {

    async postMlmdRequest (apikey: string, input: MlmdInput): Promise<PostProductResponse> {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/mlmd`, {
                method: 'POST',
                headers: {
                    'api-key': apikey,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(input)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            return response.json();
        } catch (error) {
            console.error('There was a problem with your fetch operation:', error);
            throw error;
        }
    };

    async getMlmdResult (apikey: string, id: string): Promise<GetMlmdOutput> {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/mlmd/${id}`, {
                method: 'GET',
                headers: {
                    'api-key': apikey,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            return response.json();
        } catch (error) {
            console.error('There was a problem with your fetch operation:', error);
            throw error;
        }
    };

    async getMlmdHistory (apikey: string, size?: number, lastId?: String): Promise<GetMlmdOutput[]> {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/mlmd/history?size=${size || 25}${lastId ? `&lastId=${lastId}` : ''}`, {
                method: 'GET',
                headers: {
                    'api-key': apikey,
                    'Content-Type': 'application/json',
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            return response.json();
        } catch (error) {
            console.error('There was a problem with your fetch operation:', error);
            throw error;
        }
    };
};
