import {MlmdProcessOutput} from "../../../../types/route/mlmd/MlmdProcessOutput";
import {sampleMlmdInput} from "./SampleMlmdInput";

export const sampleMlmdProcessOutput : MlmdProcessOutput = {
    optimalRoute: {
        deliveryRoutes: [
            {
                legs: [
                    {
                        point: {
                            coordinate: {
                                latitude: 39.985300,
                                longitude: 32.710000
                            },
                            category: 0,
                            priority: 0,
                            clusterIndex: 0,
                            desi: 0.1
                        },
                        arrivalTime: new Date().toString()
                    },
                    {
                        point: {
                            originalDeliveryPoints: [
                                {
                                    coordinate: {
                                        latitude: 39.985400,
                                        longitude: 32.712000
                                    },
                                    category: 1,
                                    priority: 2,
                                    clusterIndex: 0,
                                    desi: 5.3
                                },
                                {
                                    coordinate: {
                                        latitude: 39.985600,
                                        longitude: 32.714000
                                    },
                                    category: 0,
                                    priority: 1,
                                    clusterIndex: 0,
                                    desi: 10
                                },
                            ],
                            coordinate: {
                                latitude: 39.985500,
                                longitude: 32.713000
                            },
                            priority: 2,
                            clusterIndex: 0
                        },
                        arrivalTime: new Date().toString()
                    }
                ],
                courier: sampleMlmdInput.couriers[0]
            },
            {
                legs: [
                    {
                        point: {
                            coordinate: {
                                latitude: 39.985300,
                                longitude: 32.710000
                            },
                            category: 0,
                            priority: 0,
                            clusterIndex: 0,
                            desi: 0.1
                        },
                        arrivalTime: new Date().toString()
                    },
                    {
                        point: {
                            originalDeliveryPoints: [
                                {
                                    coordinate: {
                                        latitude: 39.985400,
                                        longitude: 32.712000
                                    },
                                    category: 1,
                                    priority: 2,
                                    clusterIndex: 0,
                                    desi: 5.3
                                },
                                {
                                    coordinate: {
                                        latitude: 39.985600,
                                        longitude: 32.714000
                                    },
                                    category: 0,
                                    priority: 1,
                                    clusterIndex: 0,
                                    desi: 10
                                },
                            ],
                            coordinate: {
                                latitude: 39.985500,
                                longitude: 32.713000
                            },
                            priority: 2,
                            clusterIndex: 0
                        },
                        arrivalTime: new Date().toString()
                    }
                ],
                courier: sampleMlmdInput.couriers[1]
            }
        ],
        objective: {
            totalDistanceTravelled: 12.345,
            totalPriorityCost: 1234,
            maxDistancePerCourier: 123123,
            maxDurationPerCourier: 123,
        }
    }
}