import React from "react";
import {Tabs} from "@cloudscape-design/components";
import LastMileDeliveryIntroduction from "./LastMileDeliveryIntroduction";
import LastMileDeliveryNewRequest from "./LastMileDeliveryNewRequest";
import LastMileDeliveryHistory from "./LastMileDeliveryHistory";
import {useTabContext} from "./context/TabContext";
import LastMileDeliveryConfiguration from "./LastMileDeliveryConfiguration";

const LastMileDeliveryTabs: React.FC = () => {
    const {activeTabId, setActiveTabId} = useTabContext();
    return (
        <Tabs
            tabs={[
                {
                    label: "Introduction",
                    id: "introduction",
                    content: <LastMileDeliveryIntroduction/>
                },
                {
                    label: "Configuration",
                    id: "configuration",
                    content: <LastMileDeliveryConfiguration/>
                },
                {
                    label: "New Request",
                    id: "new-request",
                    content: <LastMileDeliveryNewRequest/>
                },
                {
                    label: "History",
                    id: "history",
                    content: <LastMileDeliveryHistory/>
                }
            ]} onChange={(e) => setActiveTabId(e.detail.activeTabId)} activeTabId={activeTabId}/>
    );
};
export default LastMileDeliveryTabs;