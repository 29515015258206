import React, {PropsWithChildren} from "react";
import {Spinner, StatusIndicator} from "@cloudscape-design/components";
import {ProcessStatus} from "../types/route/ProcessStatus";

type ProcessStatusIndicatorProps = {
    status: ProcessStatus | undefined
    error: string | undefined
}

const ProcessStatusIndicator : React.FC<PropsWithChildren<ProcessStatusIndicatorProps>> = ({ status, error }) => {
    if(!status) {
        return <Spinner/>
    }
    switch (status){
        case ProcessStatus.FINISHED:
            return <StatusIndicator type='success'>Finished</StatusIndicator>;
        case ProcessStatus.PROCESSING:
            return <StatusIndicator type='in-progress'>In Progress</StatusIndicator>;
        case ProcessStatus.CREATED:
            return <StatusIndicator type='info'>Created</StatusIndicator>;
        case ProcessStatus.FAILED:
            return <StatusIndicator type='error'>Failed - {error}</StatusIndicator>;
        default:
            return <StatusIndicator type='error'>Unexpected Error</StatusIndicator>;
    }
}
export default ProcessStatusIndicator;