import React from "react";
import {TopNavigation} from "@cloudscape-design/components";
import logo from './Optimasoft.svg';
import {useUser} from "../context/UserContext";
import {useAuthenticator} from "@aws-amplify/ui-react";

const TopNavigationComponent : React.FC = () => {
    const {name, company} = useUser();
    const { signOut } = useAuthenticator();
    const i18nStrings = {
        searchIconAriaLabel: 'Search',
        searchDismissIconAriaLabel: 'Close search',
        overflowMenuTriggerText: 'More',
        overflowMenuTitleText: 'All',
        overflowMenuBackIconAriaLabel: 'Back',
        overflowMenuDismissIconAriaLabel: 'Close menu',
    };

    return (
        <TopNavigation
            i18nStrings={i18nStrings}
            identity={{
                href: '/',
                title: 'OptimaSoft',
                logo: { src: logo, alt: 'OptimaSoft' },
            }}
            utilities={[
                { type: 'button', iconName: 'settings', title: 'Settings', ariaLabel: 'Settings', href: '/options' },
                {
                    type: 'menu-dropdown',
                    text: `${company} / ${name}`,
                    iconName: 'user-profile',
                    items: [{ id: 'signout', text: 'Sign out'}],
                    onItemClick: (e) => {
                        if(e.detail.id === 'signout') {
                            signOut();
                        }
                    }
                },
            ]}
        />
    );
}

export default TopNavigationComponent;