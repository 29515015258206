import React from "react";

const SendIcon: React.FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M22,2 L11,13"></path>
            <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
        </svg>
    );
};

export default SendIcon;