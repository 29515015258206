import {Badge, Box, ColumnLayout, Header} from "@cloudscape-design/components";
import React from "react";

export function CoordinateIntroductionView() {
    return (
        <Box>
            <Badge>Coordinate</Badge>
            <ColumnLayout columns={3} borders='all'>
                <Header variant='h3'>Property</Header>
                <Header variant='h3'>Description</Header>
                <Header variant='h3'>Example / Details</Header>
                <p>latitude</p>
                <p>Latitude of the coordinate</p>
                <p>float</p>
                <p>longitude</p>
                <p>Longitude of the coordinate</p>
                <p>float</p>
            </ColumnLayout>
        </Box>
    );
}

export function DeliveryPointIntroductionView() {
    return (
        <Box>
            <Badge>DeliveryPoint</Badge>
            <ColumnLayout columns={3} borders='all'>
                <h5>Property</h5>
                <h5>Description</h5>
                <h5>Example / Details</h5>
                <p>coordinate</p>
                <p>The coordinate where the deliveryPoint is</p>
                <p><Badge>Coordinate</Badge> object</p>
                <p>category</p>
                <p>The category of the delivery, indicating specific characteristics like reception delay.</p>
                <p>"With reception" (allows setting category-specific latency in settings)</p>
                <p>priority</p>
                <p>A soft priority to influence delivery order without overriding clusterIndex.</p>
                <p>1 (higher numbers indicate higher priority)</p>
                <p>clusterIndex</p>
                <p>A hard priority that dictates the delivery order regardless of other objectives.</p>
                <p>0 (sequential order with lower numbers delivered first)</p>
                <p>desi</p>
                <p>Defines the Desi of the delivery, with options to set delivery time adjustments based on desi levels.</p>
                <p>0.05 (adds 10 seconds to delivery time if desi &lt; 0.1)</p>
            </ColumnLayout>
        </Box>
    );
}

export function ClusteredDeliveryPointIntroductionView() {
    return (
        <Box>
            <Badge>ClusteredDeliveryPoint</Badge>
            <ColumnLayout columns={3} borders='all'>
                <h5>Property</h5>
                <h5>Description</h5>
                <h5>Example / Details</h5>
                <p>originalDeliveryPoints</p>
                <p>The original deliveryPoint objects clustered into this point as they are given in the input</p>
                <p>Array of <Badge>Coordinate</Badge> object</p>
                <p>coordinate</p>
                <p>The coordinate of the middle point of the clustered original deliveryPoints</p>
                <p><Badge>Coordinate</Badge> object</p>
                <p>priority</p>
                <p>The maximum priority among the original deliveryPoints.</p>
                <p>1 (higher numbers indicate higher priority)</p>
                <p>clusterIndex</p>
                <p>One of the clusteredIndex from the list of original points. All original points must have the same clusterIndex.</p>
                <p>0 (sequential order with lower numbers delivered first)</p>
            </ColumnLayout>
        </Box>
    );
}