import React from "react";
import {TabContextProvider} from "../lastmiledelivery/context/TabContext";
import MultiLastMileDeliveryTabs from "./MultiLastMileDeliveryTabs";

const MultiLastMileDelivery: React.FC = () => {
    return (
        <TabContextProvider>
            <MultiLastMileDeliveryTabs />
        </TabContextProvider>
    );
};
export default MultiLastMileDelivery;