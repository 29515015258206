import {ProcessStatus} from "../../../../types/route/ProcessStatus";
import {sampleDistanceMatrixInput} from "./SampleDistanceMatrixInput";
import {GetDistanceMatrixOutput} from "../../../../types/route/distancematrix/GetDistanceMatrixOutput";

export const sampleDistanceMatrixOutput: GetDistanceMatrixOutput = {
    id: '1234567890',
    input: sampleDistanceMatrixInput,
    status: ProcessStatus.FINISHED,
    processDuration: 60000,
    ttl: 123213,
    creationTime: 123123,
    nodeCount: sampleDistanceMatrixInput.coordinates.length,
    outputUrl: ''
}