import {DeliveryType} from "../../../../types/route/DeliveryType";
import {MlmdInput} from "../../../../types/route/mlmd/MlmdInput";

export const sampleMlmdInput : MlmdInput = {
    couriers: [
        {
            startTime: new Date(),
            startCoordinate: {
                latitude: 39.993600,
                longitude: 32.69000
            },
            endCoordinate: {
                latitude: 39.985300,
                longitude: 32.710000
            },
            deliveryType: DeliveryType.MOTOR_VEHICLE,
        },
        {
            startTime: new Date(),
            startCoordinate: {
                latitude: 39.99999,
                longitude: 32.71000
            },
            endCoordinate: {
                latitude: 39.96000,
                longitude: 32.72000
            },
            deliveryType: DeliveryType.MOTOR_VEHICLE,
        }
    ],
    deliveryPoints: [
        {
            coordinate: {
                latitude: 39.993591,
                longitude: 32.69038
            },
            category: 2,
            priority: 1,
            clusterIndex: 0,
            desi: 791.7543077777083
        },
        {
            coordinate: {
                latitude: 39.985635,
                longitude: 32.699896
            },
            category: 0,
            priority: 0,
            clusterIndex: 0,
            desi: 0.6529177221064008
        },
        {
            coordinate: {
                latitude: 39.985735,
                longitude: 32.696896
            },
            category: 0,
            priority: 1,
            clusterIndex: 0,
            desi: 5.123
        },
        {
            coordinate: {
                latitude: 39.99,
                longitude: 32.68
            },
            category: 0,
            priority: 1,
            clusterIndex: 0,
            desi: 188
        },
        {
            coordinate: {
                latitude: 39.97,
                longitude: 32.6643
            },
            category: 1,
            priority: 0,
            clusterIndex: 1,
            desi: 2
        },
        {
            coordinate: {
                latitude: 39.87436,
                longitude: 32.817946
            },
            category: 1,
            priority: 1,
            clusterIndex: 1,
            desi: 1
        },
        {
            coordinate: {
                latitude: 39.876234,
                longitude: 32.876123
            },
            category: 0,
            priority: 0,
            clusterIndex: 1,
            desi: 2.5
        },
        {
            coordinate: {
                latitude: 39.657123,
                longitude: 32.871623
            },
            category: 0,
            priority: 0,
            clusterIndex: 1,
            desi: 3
        },
        {
            coordinate: {
                latitude: 40.04213,
                longitude: 32.83673
            },
            category: 0,
            priority: 0,
            clusterIndex: 0,
            desi: 5.2134
        }
    ]
}