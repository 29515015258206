import {BaseIconOptions, Icon} from "leaflet";

const defaultIconProps: BaseIconOptions = {
    iconSize: [24, 24],
    iconAnchor: [12,12],
    popupAnchor: [0,-24]};

export const BALLOON_ICON = new Icon({
    iconUrl: 'data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 24 24\' width=\'24\' height=\'24\'%3E%3Cpath d=\'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S10.62 6.5 12 6.5s2.5 1.12 2.5 2.5S13.38 11.5 12 11.5z\'/%3E%3C/svg%3E\n',
    ...defaultIconProps
});

export const STARTING_POINT_ICON = new Icon({
    iconUrl: 'data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 24 24\' width=\'24\' height=\'24\'%3E%3Ccircle cx=\'12\' cy=\'12\' r=\'10\' fill=\'%2300FF00\' /%3E%3Ctext x=\'50%25\' y=\'50%25\' dy=\'.35em\' text-anchor=\'middle\' fill=\'white\' font-size=\'12\' font-family=\'Arial\'%3E S %3C/text%3E%3C/svg%3E\n',
    ...defaultIconProps
});

export const END_POINT_ICON = new Icon({
    iconUrl: 'data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 24 24\' width=\'24\' height=\'24\'%3E%3Ccircle cx=\'12\' cy=\'12\' r=\'10\' fill=\'%23FF0000\' /%3E%3Ctext x=\'50%25\' y=\'50%25\' dy=\'.35em\' text-anchor=\'middle\' fill=\'white\' font-size=\'12\' font-family=\'Arial\'%3E E %3C/text%3E%3C/svg%3E\n',
    ...defaultIconProps
});

export const DELIVERY_POINT_ICON = new Icon({
    iconUrl: 'data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 100 100\' width=\'100\' height=\'100\'%3E%3Ccircle cx=\'50\' cy=\'50\' r=\'45\' fill=\'%23007bff\'/%3E%3Ctext x=\'50%25\' y=\'50%25\' dy=\'0.35em\' fill=\'white\' font-size=\'50\' font-family=\'Arial\' text-anchor=\'middle\'%3E D %3C/text%3E%3C/svg%3E\n',
    ...defaultIconProps
});