import {GetLmdOutput} from "../../../../types/route/lmd/GetLmdOutput";
import {ProcessStatus} from "../../../../types/route/ProcessStatus";
import {sampleLmdInput} from "./SampleLmdInput";

export const sampleGetLmdOutput : GetLmdOutput = {
    id: '1234567890',
    input: sampleLmdInput,
    status: ProcessStatus.FINISHED,
    processDuration: 60000,
    ttl: 123213,
    creationTime: 123123,
    nodeCount: sampleLmdInput.deliveryPoints.length,
    outputUrl: ''
}