import React, {Dispatch, SetStateAction, useState} from "react";
import {Button, FormField, Input, Table} from "@cloudscape-design/components";

type MapInputComponentProps = {
    map: {[key: number]: number},
    setMap : Dispatch<SetStateAction<{[key: number]: number}>>,
    keyLabel: string,
    keyDescription: string,
    valueLabel: string,
    valueDescription: string
}

export const MapInputComponent : React.FC<MapInputComponentProps> = ({map, setMap, keyLabel, keyDescription, valueLabel, valueDescription}) => {
    const [key, setKey] = useState<number | undefined>();
    const [value, setValue] = useState<number | undefined>();
    const createKeys = (map: {[key: number]: number}) => Object.keys(map).map(parseFloat).sort((a, b) => a - b);

    // const keys = useMemo(() => new Set(Object.keys(map).map(key => parseFloat(key))), [map]);
    const [orderedKeys, setOrderedKeys] = useState<number[]>(createKeys(map));

    const handleAddEntry = () => {
        if (key !== undefined) {
            if(value !== undefined) {
                setMap(prev => {
                    const updatedMap = {...prev, [key]: value};
                    setOrderedKeys(createKeys(updatedMap));
                    return updatedMap;
                });
            } else {
                setMap(prev => {
                    delete prev[key];
                    setOrderedKeys(createKeys(prev));
                    return prev;
                });
            }
            setKey(undefined);
            setValue(undefined);
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '30px'}}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent:'space-between', alignItems: 'center', gap: '30px'}}>
                <FormField description={keyDescription} label={keyLabel}>
                    <Input
                        onChange={({ detail }) => setKey(detail.value === '' ? undefined : parseFloat(detail.value))}
                        value={key === undefined ? '' : key.toString()}
                        type="number"
                        inputMode='decimal'
                    />
                </FormField>
                <FormField description={valueDescription} label={valueLabel}>
                    <Input
                        onChange={({ detail }) => setValue(detail.value === '' ? undefined : parseFloat(detail.value))}
                        value={value === undefined ? '' : value.toString()}
                        type="number"
                        inputMode='decimal'
                    />
                </FormField>
                <Button onClick={handleAddEntry} disabled={key === undefined}>{key === undefined ? 'Add/Update' : new Set(orderedKeys).has(key) ? (value === undefined ? 'Remove' : 'Update') : 'Add'} Entry</Button>
            </div>
            <Table items={orderedKeys.map((orderedKey) => { return {key: orderedKey, value: map[orderedKey]}})}
                   columnDefinitions={[
                       {
                           id: keyLabel,
                           header: keyLabel,
                           cell: item => item.key},
                       {
                           id: valueLabel,
                           header: valueLabel,
                           cell: item => item.value}]}/>
        </div>
    );
}
