import React from "react";
import {ColumnLayout, Container, Header, SpaceBetween} from "@cloudscape-design/components";
import ValueWithLabel from "../components/ValueWithLabel";
import {useUser} from "../context/UserContext";

const AccountOptionsPage: React.FC = () => {
    const {name, company, email_verified, email, apiKey} = useUser();
    return (
        <div style={{marginTop: '30px'}}>
            <ColumnLayout>
                <Container header={<Header headingTagOverride="h3">Account Settings</Header>}>
                    <ColumnLayout columns={1} variant="text-grid">
                        <SpaceBetween size="l">
                            <ValueWithLabel label="Company">{company}</ValueWithLabel>
                            <ValueWithLabel label="Name">{name}</ValueWithLabel>
                            <ValueWithLabel label="Email">{email}</ValueWithLabel>
                            <ValueWithLabel label="Email Verified Status">{email_verified}</ValueWithLabel>
                            <ValueWithLabel label="ApiKey">{apiKey}</ValueWithLabel>
                        </SpaceBetween>
                    </ColumnLayout>
                </Container>
            </ColumnLayout>
        </div>
    );
};

export default AccountOptionsPage;