import {DistanceMatrixInput} from "../../../../types/route/distancematrix/DistanceMatrixInput";

export const sampleDistanceMatrixInput : DistanceMatrixInput = {
    coordinates: [
        {
            latitude: 39.993591,
            longitude: 32.69038
        },
        {
            latitude: 39.985635,
            longitude: 32.699896
        },
        {
            latitude: 39.984635,
            longitude: 32.679896
        },
        {
            latitude: 39.955375,
            longitude: 32.691896
        },
        {
            latitude: 39.985635,
            longitude: 32.706896
        }
    ]
}