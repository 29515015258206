import React from "react";
import {AuthenticationWrapper} from "./authentication/AuthenticationWrapper";
import {UserProvider} from "./context/UserContext";
import MainRouter from "./topnavigation/MainRouter";
import TopNavigationComponent from "./topnavigation/TopNavigationComponent";
import { I18nProvider } from '@cloudscape-design/components/i18n';
import enMessages from '@cloudscape-design/components/i18n/messages/all.en.json';

function App() {

  return (
      <I18nProvider locale="en" messages={[enMessages]}>
          <AuthenticationWrapper>
              <UserProvider>
                  <TopNavigationComponent />
                  <MainRouter/>
              </UserProvider>
          </AuthenticationWrapper>
      </I18nProvider>
  );
}

export default App;
