import {PostProductResponse} from "../../types/route/PostProductResponse";
import {DistanceMatrixInput} from "../../types/route/distancematrix/DistanceMatrixInput";
import {GetDistanceMatrixOutput} from "../../types/route/distancematrix/GetDistanceMatrixOutput";

export class DistanceMatrixClient {

    async postDistanceMatrixRequest (apikey: string, input: DistanceMatrixInput): Promise<PostProductResponse> {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/distance`, {
                method: 'POST',
                headers: {
                    'api-key': apikey,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(input)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            return response.json();
        } catch (error) {
            console.error('There was a problem with your fetch operation:', error);
            throw error;
        }
    };

    async getDistanceMatrixResult (apikey: string, id: string): Promise<GetDistanceMatrixOutput> {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/distance/${id}`, {
                method: 'GET',
                headers: {
                    'api-key': apikey,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            return response.json();
        } catch (error) {
            console.error('There was a problem with your fetch operation:', error);
            throw error;
        }
    };

    async getDistanceMatrixHistory (apikey: string, size?: number, lastId?: String): Promise<GetDistanceMatrixOutput[]> {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/distance/history?size=${size || 25}${lastId ? `&lastId=${lastId}` : ''}`, {
                method: 'GET',
                headers: {
                    'api-key': apikey,
                    'Content-Type': 'application/json',
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            return response.json();
        } catch (error) {
            console.error('There was a problem with your fetch operation:', error);
            throw error;
        }
    };
};
