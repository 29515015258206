import React, {useEffect, useState} from "react";
import {LmdInput} from "../../../types/route/lmd/LmdInput";
import SendIcon from "../icon/SendIcon";
import {useApiClient} from "../../../client/OptimaSoftClient";
import {useUser} from "../../../context/UserContext";
import {PostProductResponse} from "../../../types/route/PostProductResponse";
import {Box, Button, FileUpload, Flashbar, Header, Link, Modal, Spinner} from "@cloudscape-design/components";
import {downloadJson} from "../../../util/downloadUtil";
import FileIcon from "../icon/FileIcon";
import {useTabContext} from "./context/TabContext";
import LmdOutputView from "./components/LmdOutputView";
import {sampleLmdInput} from "./samples/SampleLmdInput";

const LastMileDeliveryNewRequest: React.FC = () => {
    const [lmdInput, setLmdInput] = useState<LmdInput>();
    const [postLmdResponse, setPostLmdResponse] = useState<PostProductResponse|undefined>();
    const [requestFileVisible, setRequestFileVisible] = React.useState(false);
    const [files, setFiles] = useState<File[]>([]);
    const {apiKey } = useUser();
    const {lmdClient} = useApiClient();
    const [isRestLoading, setIsRestLoading] = useState(false);
    const {setActiveTabId} = useTabContext();

    useEffect(() => {
        if(files.length === 1) {
            const firstFile = files[0];
            firstFile.text().then(text => setLmdInput(JSON.parse(text as string)));
        }
    }, [files]);

    const sendRequest = () => {
        if(!apiKey || !lmdInput)
            return;
        setIsRestLoading(true);
        lmdClient.postLmdRequest(apiKey, lmdInput)
            .then(lmdResponse => setPostLmdResponse(lmdResponse))
            .finally(() => setIsRestLoading(false));
    };


    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '30px'}}>
            <Box>
                <Header>Send Your Last Mile Delivery Request</Header>
                <p>To optimize your delivery process using our Last Mile Delivery Application,
                    you will need to provide specific details about your delivery requirements in a JSON format.
                    This information includes start and end coordinates, delivery points, priority levels,
                    and any additional delivery parameters that our system uses to calculate the most efficient delivery routes.</p>
                <p>Not sure how to format your request? Download our <Link onFollow={(e) =>
                    downloadJson('SampleLmdInput.json', sampleLmdInput)}>sample JSON file<FileIcon/></Link>to get started.
                    This template includes all necessary fields and example values to guide you through creating your request.</p>
                <p>How to Send Your Request:</p>
                <ol>
                    <li><strong>Prepare Your JSON File:</strong> Ensure your file follows the format shown in the sample JSON.
                        Include all required information about your delivery points and preferences.</li>
                    <li><strong>Upload Your File:</strong> Use the upload section below to select and upload your JSON file.</li>
                    <li><strong>Send Your Request:</strong> Once your file is uploaded, click the "Send Request" button.
                        You will see a status indicator while our servers process your request. This may take a few moments.</li>
                    <li><strong>View Your Results:</strong> After processing, the optimized delivery route and any additional
                        information will be displayed on this page. You can use these insights to plan your delivery operations more efficiently.</li>
                </ol>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                    <FileUpload
                        onChange={({ detail }) => setFiles(detail.value)}
                        value={files}
                        i18nStrings={{
                            uploadButtonText: e =>
                                e ? "Choose files" : "Choose file",
                            dropzoneText: e =>
                                e
                                    ? "Drop files to upload"
                                    : "Drop file to upload",
                            removeFileAriaLabel: e =>
                                `Remove file ${e + 1}`,
                            limitShowFewer: "Show fewer files",
                            limitShowMore: "Show more files",
                            errorIconAriaLabel: "Error"
                        }}
                        multiple={false}
                        showFileLastModified
                        showFileSize
                        showFileThumbnail
                        tokenLimit={3}
                        constraintText="Last Mile Delivery Request Json File"
                    />
                    {lmdInput && <>
                            <Button onClick={() => setRequestFileVisible(true)}>
                                Preview Json
                            </Button>
                            <Modal
                                onDismiss={() => setRequestFileVisible(false)}
                                visible={requestFileVisible}
                                header="Last Mile Delivery Request Json File Preview">
                                <pre style={{ whiteSpace: 'pre', wordBreak: 'break-word', margin: '0', padding: '0.5rem' , overflowX: 'scroll'}}>
                                    {JSON.stringify(lmdInput, null, 2)}
                                </pre>
                            </Modal>
                        </>
                    }
                </div>
            </Box>
            <Box>
                <Button onClick={sendRequest} disabled={!lmdInput || isRestLoading}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px'}}>
                        {isRestLoading ? (
                            <>
                                <Spinner/>
                                <div>Sending Request</div>
                            </>
                        ) : (
                            <>
                                <SendIcon/>
                                <div>Send Request</div>
                            </>
                        )}
                    </div>
                </Button>
            </Box>
            {postLmdResponse && (
                <Flashbar items={[{
                    id: 'success-flashbar',
                    type: 'success',
                    dismissible: true,
                    dismissLabel: 'Dismiss Last Mile Delivery Request Id',
                    onDismiss: () => setPostLmdResponse(undefined),
                    content: (
                        <>
                            The request has been <strong>successfully</strong> dispatched to the server.
                            You can now check the current status of the processing or examine the outcome below.
                            You can also view the request in the
                            <Link color="inverted" variant='primary' onFollow={e=> setActiveTabId('history')}>History tab</Link>.
                            For Reference, your requestId is {postLmdResponse?.requestId}
                        </>
                    )
                }]}/>
            )}
            {postLmdResponse?.requestId && <LmdOutputView lmdId={postLmdResponse.requestId}/>}
        </div>
    );
};

export default LastMileDeliveryNewRequest;