import React from "react";
import {TabContextProvider} from "../lastmiledelivery/context/TabContext";
import DistanceMatrixTab from "./DistanceMatrixTabs";

const DistanceMatrix: React.FC = () => {
    return (
        <TabContextProvider>
            <DistanceMatrixTab />
        </TabContextProvider>
    );
};

export default DistanceMatrix;