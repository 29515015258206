import React, {useEffect, useState} from "react";
import {Stack} from "../../../util/Stack";
import {useApiClient} from "../../../client/OptimaSoftClient";
import {useUser} from "../../../context/UserContext";
import {Pagination, Table} from "@cloudscape-design/components";
import LmdOutputView from "../lastmiledelivery/components/LmdOutputView";
import {GetMlmdOutput} from "../../../types/route/mlmd/GetMlmdOutput";
import MlmdOutputView from "./components/MlmdOutputView";
import {DATE_TIME_FORMAT_OPTIONS} from "../../../util/timeUtil";

type CurrentPage = {
    previousIdStack: Stack<string>,
    pageIndex: number,
    pageSize: number,
    isLastPage: boolean,
    currentItems: GetMlmdOutput[],
    selectedItem?: GetMlmdOutput,
    currentId?: string,
    nextId?: string,
}

const MultiLastMileDeliveryHistory : React.FC = () => {
    const { mlmdClient } = useApiClient();
    const {apiKey} = useUser();
    const [currentPage, setCurrentPage] = useState<CurrentPage>({previousIdStack: new Stack<string>(), pageIndex: 0, isLastPage: false, pageSize: 25, currentItems: []});
    const [selectedItem, setSelectedItem] = useState<GetMlmdOutput|undefined>();

    const loadPreviousPage = (currentPage: CurrentPage) => {
        const previousId = currentPage.previousIdStack.pop();
        mlmdClient.getMlmdHistory(apiKey, currentPage.pageSize, previousId).then(mlmdHistory => {
            setCurrentPage({
                ...currentPage,
                pageIndex: currentPage.pageIndex-1,
                currentId: previousId,
                isLastPage: false,
                nextId: currentPage.currentId,
                currentItems: mlmdHistory
            });
        });
    }

    const loadNextPage = (currentPage: CurrentPage) => {
        mlmdClient.getMlmdHistory(apiKey, currentPage.pageSize, currentPage.nextId).then(mlmdHistory => {
            if(mlmdHistory.length === 0){
                setCurrentPage({
                    ...currentPage,
                    isLastPage: true,
                });
                return;
            }
            const lastId = mlmdHistory[mlmdHistory.length - 1].id;
            currentPage.currentId && currentPage.previousIdStack.push(currentPage.currentId);
            setCurrentPage({
                ...currentPage,
                pageIndex: currentPage.pageIndex+1,
                currentId: currentPage.nextId,
                isLastPage: false,
                nextId: lastId,
                currentItems: mlmdHistory
            });
        });
    }

    useEffect(() => {
        loadNextPage(currentPage);
    }, []);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '30px'}}>
            <Table items={currentPage.currentItems}
                   onSelectionChange={({ detail }) =>
                   {
                       setSelectedItem(detail.selectedItems[0]);
                   }}
                   selectedItems={selectedItem ? [selectedItem] : []}
                   selectionType='single'
                   loadingText="Loading historical Lmd requests"
                   pagination={
                       <Pagination currentPageIndex={currentPage.pageIndex}
                                   pagesCount={currentPage.currentItems.length < currentPage.pageSize || currentPage.isLastPage ? currentPage.pageIndex : currentPage.pageIndex + 1}
                                   onPreviousPageClick={e => loadPreviousPage(currentPage)}
                                   onNextPageClick={e => loadNextPage(currentPage)}
                       />
                   }
                   columnDefinitions={[
                       {id: 'id', header: 'Id', cell: item => item.id, sortingField: 'id', isRowHeader: true},
                       {id: 'creationTime', header: 'CreationTime', cell: item => new Date(item.creationTime).toLocaleDateString(undefined, DATE_TIME_FORMAT_OPTIONS), sortingField: 'creationTime'},
                       {id: 'nodeCount', header: 'NodeCount', cell: item => item.nodeCount, sortingField: 'NodeCount'},
                       {id: 'status', header: 'Status', cell: item => item.status, sortingField: 'Status'},
                       {id: 'processDuration', header: 'ProcessDuration', cell: item => item.processDuration, sortingField: 'ProcessDuration'},
                       {id: 'ttl', header: 'Ttl', cell: item => new Date(item.ttl).toLocaleDateString(undefined, DATE_TIME_FORMAT_OPTIONS), sortingField: 'Ttl'},
                       {id: 'failReason', header: 'FailReason', cell: item => item.failReason, sortingField: 'FailReason'}]}/>
            {selectedItem && <MlmdOutputView mlmdId={selectedItem?.id}/>}
        </div>
    );
}

export default MultiLastMileDeliveryHistory;