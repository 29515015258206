import React from "react";
import {useTabContext} from "../lastmiledelivery/context/TabContext";
import {Tabs} from "@cloudscape-design/components";
import MultiLastMileDeliveryIntroduction from "./MultiLastMileDeliveryIntroduction";
import MultiLastMileDeliveryHistory from "./MultiLastMileDeliveryHistory";
import MultiLastMileDeliveryNewRequest from "./MultiLastMileDeliveryNewRequest";
import MultiLastMileDeliveryConfiguration from "./MultiLastMileDeliveryConfiguration";

const DistanceMatrixTab: React.FC = () => {
    const {activeTabId, setActiveTabId} = useTabContext();
    return (
        <Tabs
            tabs={[
                {
                    label: "Introduction",
                    id: "introduction",
                    content: <MultiLastMileDeliveryIntroduction/>
                },
                {
                    label: "Configuration",
                    id: "configuration",
                    content: <MultiLastMileDeliveryConfiguration/>
                },
                {
                    label: "New Request",
                    id: "new-request",
                    content: <MultiLastMileDeliveryNewRequest/>
                },
                {
                    label: "History",
                    id: "history",
                    content: <MultiLastMileDeliveryHistory/>
                }
            ]} onChange={(e) => setActiveTabId(e.detail.activeTabId)} activeTabId={activeTabId}/>
    );
};

export default DistanceMatrixTab;