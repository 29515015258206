import React from "react";
import { useAuth } from "@aws-amplify/ui-react/internal";
import {ColumnLayout, Container, Header, SpaceBetween} from "@cloudscape-design/components";
import ValueWithLabel from "../components/ValueWithLabel";

export const HomePage = () => {
  const { user } = useAuth();
  return (
      <div style={{marginTop: '30px'}}>
              <Header variant={'h1'}>Welcome to OptimaSoft</Header>
              <i>Empowering Efficiency: Next-Generation Optimization Solutions for Modern Challenges</i>
              <Header variant={'h3'}>Our Journey</Header>
              <p>Founded in 2022, OptimaSoft has been on the forefront of developing innovative software
                  solutions aimed at revolutionizing how businesses handle optimization challenges on a large scale.
                  With a commitment to excellence and innovation, we launched our first product in April 2024,
                  setting a new standard in the optimization software industry.</p>
              <Header variant={'h3'}>What We Do</Header>
              <p>At OptimaSoft, we specialize in providing Software as a Service (SaaS) solutions designed to tackle
                  complex optimization problems across various industries. Our cutting-edge technology and
                  sophisticated algorithms ensure that our clients can achieve optimal efficiency and enhanced
                  operational effectiveness.</p>
              <Header variant={'h3'}>Our Products</Header>
              <p>We are proud to offer a suite of products that represent the pinnacle of optimization technology:</p>
              <ul>
                  <li>
                      <b>Distance Matrix:</b> Simplify and optimize route planning with our robust distance matrix
                      computation service, designed to save time and reduce costs for logistics and transportation companies.
                  </li>
                  <li>
                      <b>Last Mile Delivery:</b> Revolutionize your delivery services with our Last Mile Delivery
                      solution. Designed to streamline the delivery process, this tool helps companies ensure timely
                      and cost-effective deliveries, directly impacting customer satisfaction and operational efficiency.
                  </li>
                  <li>
                      <b>Multi Last Mile Delivery:</b> Expand the capabilities of traditional last mile delivery with
                      our Multi Last Mile Delivery product. Perfect for businesses managing numerous deliveries
                      across multiple locations, this solution offers advanced route planning and scheduling features
                      to handle high-volume, complex delivery needs efficiently.
                  </li>
              </ul>
              <Header variant={'h3'}>Our Mission</Header>
              <p>Our goal is to continuously expand our portfolio of optimization tools and services, constantly
                  seeking new challenges and opportunities in optimization. OptimaSoft is dedicated to developing
                  solutions that not only meet but exceed the modern-day demands of businesses worldwide.</p>
              <Header variant={'h3'}>Looking Forward</Header>
              <p>As we move forward, [Your Company Name] aims to address an increasing number of optimization problems,
                  enhancing our existing solutions and venturing into new domains of efficiency. With a relentless focus
                  on innovation and quality, we are committed to helping our clients thrive in an ever-evolving digital
                  landscape.</p>
              <Header variant={'h3'}>Join Us on Our Journey</Header>
              <p>Explore the possibilities with OptimaSoft and take your business’s efficiency to the next level.
                  Contact us today at info@optima-soft.co to learn more about our products and how we can help solve
                  your optimization challenges.</p>
      </div>
  );
};
