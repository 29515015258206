import React from "react";
import {TabContextProvider} from "../lastmiledelivery/context/TabContext";
import AdminTabs from "./AdminTabs";

const Admin: React.FC = () => {
    return (
        <TabContextProvider defaultTab={'map-integrity'} >
            <AdminTabs />
        </TabContextProvider>
    );
};
export default Admin;