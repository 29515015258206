import React, {PropsWithChildren} from "react";
import {Box} from "@cloudscape-design/components";

type ValueWithLabelProps = {
    label: string
}

const ValueWithLabel : React.FC<PropsWithChildren<ValueWithLabelProps>> = ({ label, children }) => (
    <div>
        <Box variant="awsui-key-label">{label}</Box>
        <div>{children}</div>
    </div>
);

export default ValueWithLabel;