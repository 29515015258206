export const useFileServiceClient = () => {
    async function fetchJsonData<T>(url: string): Promise<T> {
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data: T = await response.json();
            return data;
        } catch (error) {
            console.error("Failed to fetch data:", error);
            throw new Error('Failed to fetch data');
        }
    }

    return { fetchJsonData };
};
