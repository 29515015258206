import React from "react";
import {TabContextProvider} from "./context/TabContext";
import LastMileDeliveryTabs from "./LastMileDeliveryTabs";

const LastMileDelivery: React.FC = () => {
    return (
        <TabContextProvider>
            <LastMileDeliveryTabs/>
        </TabContextProvider>
    );
};
export default LastMileDelivery;