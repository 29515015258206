import {ProcessStatus} from "../../../../types/route/ProcessStatus";
import {sampleMlmdInput} from "./SampleMlmdInput";
import {GetMlmdOutput} from "../../../../types/route/mlmd/GetMlmdOutput";

export const sampleMlmdOutput : GetMlmdOutput = {
    id: '1234567890',
    input: sampleMlmdInput,
    status: ProcessStatus.FINISHED,
    processDuration: 60000,
    ttl: 123213,
    creationTime: 123123,
    nodeCount: sampleMlmdInput.deliveryPoints.length,
    outputUrl: ''
}