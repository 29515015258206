import {Configuration, Product} from "../../types/route/Configuration";

export class ConfigurationClient {
    async postConfiguration<T>(apikey: string, input: T): Promise<T> {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/configuration`, {
                method: 'POST',
                headers: {
                    'api-key': apikey,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(input)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            return response.json();
        } catch (error) {
            console.error('There was a problem with your fetch operation:', error);
            throw error;
        }
    };

    async getConfiguration<T>(apikey: string, product: Product): Promise<Configuration<T>> {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/configuration/${product}`, {
                method: 'GET',
                headers: {
                    'api-key': apikey,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            return response.json();
        } catch (error) {
            console.error('There was a problem with your fetch operation:', error);
            throw error;
        }
    };
};
